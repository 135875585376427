import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './components/Home'
import About from './components/About'
import Contact from './components/Contact'
import Order from './components/Order'
import SanitationOrder from './components/SanitationOrder'
import OffertOnParty from './components/Offert_on_party'
import OffertOnBuildplace from './components/Offert_on_buildplace'
import OffertOnEvents from './components/Offert_on_events'
import OffertSanitation from './components/Offert_sanitation'
import Elblag from './components/localizations_cities/Elblag'
import Gdansk from './components/localizations_cities/Gdansk'
import Gdynia from './components/localizations_cities/Gdynia'
import Ostroda from './components/localizations_cities/Ostroda'
import Ndgdanski from './components/localizations_cities/Ndgdanski'
import Tczew from './components/localizations_cities/Tczew'
import Paslek from './components/localizations_cities/Paslek'
import Malbork from './components/localizations_cities/Malbork'
import Braniewo from './components/localizations_cities/Braniewo'
import Morag from './components/localizations_cities/Morag'
import Stegna from './components/localizations_cities/Stegna'
import Jantar from './components/localizations_cities/Jantar'
import Prgdanski from './components/localizations_cities/Prgdanski'
import './App.css'

const App = () => (

  <div className="app--wrapper">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/offert/on_party' element={<OffertOnParty />} />
          <Route path='/offert/on_build_place' element={<OffertOnBuildplace />} />
          <Route path='/offert/on_events' element={<OffertOnEvents />} />
          <Route path='/offert/sanitation' element={<OffertSanitation />} />
          <Route path='/orderFunc' element={<Order />} />
          <Route path='/sanitationOrder' element={<SanitationOrder />} />
          <Route path='/localization/elblag' element={<Elblag />} />
          <Route path='/localization/gdansk' element={<Gdansk />} />
          <Route path='/localization/gdynia' element={<Gdynia />} />
          <Route path='/localization/ostroda' element={<Ostroda />} />
          <Route path='/localization/nowy_dwor_gdanski' element={<Ndgdanski />} />
          <Route path='/localization/tczew' element={<Tczew />} />
          <Route path='/localization/paslek' element={<Paslek />} />
          <Route path='/localization/malbork' element={<Malbork />} />
          <Route path='/localization/braniewo' element={<Braniewo />} />
          <Route path='/localization/morag' element={<Morag />} />
          <Route path='/localization/stegna' element={<Stegna />} />
          <Route path='/localization/jantar' element={<Jantar />} />
          <Route path='/localization/pruszcz_gdanski' element={<Prgdanski />} />
        </Routes>
      </BrowserRouter>
  </div>
)

export default App;
