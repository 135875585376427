import React from 'react'
import { useLoadScript } from '@react-google-maps/api'
import SanitationMap from './SanitationMap'

const GoogleMap = () => {

    const { isLoaded } = useLoadScript ({ 
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
        libraries:['places'], 
    })

    if(!isLoaded) return <div className="map--loading">Loading...</div>

    return <SanitationMap />

}

export default GoogleMap