import React from 'react'
import '../styles/footer.css'
import { Images } from '../assets/images/index.js'
import { GrMail } from "react-icons/gr"
import { FaPhoneAlt } from "react-icons/fa"


const FooterOrder = () => (
    <div className="footer--wrapper">
        <div className="footer--mainContainer">
            <div className="footer--contactContainer">
                <img src={Images.Logo} alt="logo" />
                <div className="footer--description">
                    <p>Adres siedziby: ul. Krakusa 8/U1,</p><p> 82-300 Elbląg </p>
                </div>
                <div className="footer--description">
                    <p>Biuro Obsługi Klienta: ul. Jaśminowa 6,</p><p> 82-300 Elbląg </p>
                </div>
                <div className="footer--contactWrapper">
                <div className="footer--media">
                    <div className="footer--iconContainer">
                        <GrMail className="" />
                    </div>
                    <p>biuro@serwistoalet.pl</p>
                </div>
                <div className="footer--media">
                    <div className="footer--iconContainer">
                        <FaPhoneAlt className="" />
                    </div>
                    <p>+48 733 435 435</p>
                </div>
            </div>
            </div>
        </div>
    </div>
)

export default FooterOrder