import React, { useEffect, useState } from "react";
import "../styles/navigation.css";
import { Images } from "../assets/images/index.js";
import { Link } from "react-router-dom";
import { BiMenu } from "react-icons/bi";
import { HiMenuAlt4 } from "react-icons/hi";
import { motion } from "framer-motion";

const Navigation = () => {
  useEffect(() => {
    window.onscroll = function () {
      stickyNavbar();
    };

    const navbar = document.querySelector("#navbar");

    function stickyNavbar() {
      if (window.pageYOffset >= 100) {
        navbar.classList.add("navigation__wrapper--sticky");
      } else {
        navbar.classList.remove("navigation__wrapper--sticky");
      }
    }
  });

  const animateMenuFrom = { y: -1500 };
  const animateMenuTo = { y: 0 };
  const animateDropdownFrom = { opacity: 0, y: -40 };
  const animateDropdownTo = { opacity: 1, y: 0 };

  const [openNav, setOpenNav] = useState(false);

  const openIcon = (
    <BiMenu
      className="hamburger"
      size="40px"
      color="rgb(0, 0, 0)"
      onClick={() => setOpenNav(!openNav)}
    />
  );

  const closeIcon = (
    <HiMenuAlt4
      className="hamburger"
      size="40px"
      color="rgb(0, 0, 0)"
      onClick={() => setOpenNav(!openNav)}
    />
  );

  const [openDropdown, setOpenDropdown] = useState(false);
  const mobDropdown = () => (
    <div className="mobNav--dropdownWrapper" id="mob--dropdown">
      <div className="dropdown--Links">
        <motion.div
          className="dropdown--linksWrapper"
          initial={animateDropdownFrom}
          animate={animateDropdownTo}
          transition={{ delay: 0.05 }}
        >
          <Link to="/offert/on_build_place">
            <p>Toalety na budowę</p>
          </Link>
        </motion.div>
        <motion.div
          className="dropdown--linksWrapper"
          initial={animateDropdownFrom}
          animate={animateDropdownTo}
          transition={{ delay: 0.1 }}
        >
          <Link to="/offert/on_party">
            <p>Toalety na imprezy</p>
          </Link>
        </motion.div>
        <motion.div
          className="dropdown--linksWrapper"
          initial={animateDropdownFrom}
          animate={animateDropdownTo}
          transition={{ delay: 0.2 }}
        >
          <Link to="/offert/on_events">
            <p>Toalety na inne okazje</p>
          </Link>
        </motion.div>
        <motion.div
          className="dropdown--linksWrapper"
          initial={animateDropdownFrom}
          animate={animateDropdownTo}
          transition={{ delay: 0.3 }}
        >
          <Link to="/offert/sanitation">
            <p>Usługi asenizacyjne</p>
          </Link>
        </motion.div>
      </div>
    </div>
  );
  const noMobDropdown = () => {
    return null;
  };

  return (
    <div className="navigation--wrapper" id="navbar">
      <div className="navigation--mainContainer">
        <div className="navigation--logoWrapper">
          <Link to="/">
            <img src={Images.Logo} alt="logo" />
          </Link>
        </div>
        <div className="navigation--linksWrapper">
          <div className="navigation--mainLinks">
            <div className="navigation--linkContainer">
              <Link to="/">
                <p>Start</p>
              </Link>
            </div>
            <div className="navigation--linkContainer">
              <Link to="/about">
                <p>O nas</p>
              </Link>
            </div>
            <div className="navigation--linkContainer dropdownLink">
              <Link to="/">
                <p>Oferta</p>
              </Link>
              <div className="dropdownLink--bridge">
                <div className="dropdown--linksContainer">
                  <div className="dropdown--linkContainer">
                    <Link to="/offert/on_build_place">
                      <p>Toalety na budowę</p>
                    </Link>
                  </div>
                  <div className="dropdown--linkContainer">
                    <Link to="/offert/on_party">
                      <p>Toalety na imprezy</p>
                    </Link>
                  </div>
                  <div className="dropdown--linkContainer">
                    <Link to="/offert/on_events">
                      <p>Toalety na inne okazje</p>
                    </Link>
                  </div>
                  <div className="dropdown--linkContainer">
                    <Link to="/offert/sanitation">
                      <p>Usługi asenizacyjne</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="navigation--linkContainer">
              <Link to="/contact">
                <p>Kontakt</p>
              </Link>
            </div>
          </div>
          <div className="navigation__orderLink--wrapper">
            <div className="navigation__orderLink--toilets">
              <Link to="/orderFunc">
                <p>Zamów toaletę</p>
              </Link>
            </div>
            <div className="navigation__orderLink--asenization">
              {/* <Link to="/sanitationOrder"><p>Zamów wywóz</p></Link> */}
              <p>Zamów wywóz</p>
              <div className="navigation__orderLink__asenization--soon"><p>WKRÓTCE!</p></div>
            </div>
          </div>
        </div>

        <div className="navigation__mobile--wrapper">
          {openNav ? closeIcon : openIcon}
          {openNav && (
            <motion.div
              initial={animateMenuFrom}
              animate={animateMenuTo}
              transition={{ duration: 0.55 }}
              className="mobNav--wrapper"
            >
              <div className="mobNav--links">
                <div className="mobNav--linkWrapper">
                  <Link to="/">
                    <p>Start</p>
                  </Link>
                </div>
                <div className="mobNav--linkWrapper">
                  <Link to="/about">
                    <p>O nas</p>
                  </Link>
                </div>
                <div className="mobNav--linkWrapper" id="mobNav--offert">
                  <p onClick={() => setOpenDropdown(!openDropdown)}>Oferta</p>
                </div>

                {openDropdown ? mobDropdown() : noMobDropdown()}

                <div className="mobNav--linkWrapper" id="mobNav--contact">
                  <Link to="/contact">
                    <p>Kontakt</p>
                  </Link>
                </div>
                <div className="mobNav--linkWrapper">
                  <Link to="/orderFunc">
                    <p>ZAMÓW TOALETĘ</p>
                  </Link>
                </div>
                <div className="mobNav--linkWrapper">
                  {/* <Link to="/sanitationOrder" ><p>ZAMÓW WYWÓZ</p></Link> */}
                  <p>ZAMÓW WYWÓZ</p><div class="navigation__orderLink__asenization--soon"><p>WKRÓTCE!</p></div>
                </div>
              </div>
            </motion.div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navigation;
