import React, { useEffect } from 'react'
import '../../styles/order.css'
import '../../styles/orderStyles/formCabins.css'
import '../../styles/orderStyles/formCalendar.css'
import { BsCheckSquareFill, BsCheckSquare } from 'react-icons/bs'
import { FiPlus, FiMinus } from 'react-icons/fi'
import { IoStorefront } from 'react-icons/io5'
import { MdShoppingCart } from 'react-icons/md'
import { FusionCabin, MtmCabin } from '../../assets/images/index.js'
import { Images } from '../../assets/images/index.js'
import { Carousel } from 'react-carousel-minimal'
import InputMask from "react-input-mask"
import Calendar from 'react-calendar'
import { addDays } from 'date-fns'

const FormCabins = ({ page, setPage, order, setOrder, dateNow, startDate, setStartDate, endDate, setEndDate, difference, orderStartDate, orderEndDate, servicePrice, setServicePrice, accesoriesPrice, setAccesoriesPrice, chandleOrderPrice, checked, setChecked }) => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])


    const chooseToiletType = (e) => {
        if (e.target === document.querySelector("#fusionInput") && checked.fusionInput !== true) {
            setChecked({ ...checked, fusionInput: !checked.fusionInput, mtmInput: !checked.mtmInput })
        }

        if (e.target === document.querySelector("#mtmInput") && checked.mtmInput !== true) {
            setChecked({ ...checked, mtmInput: !checked.mtmInput, fusionInput: !checked.fusionInput })
        }
    }

    const chooseAccesories = (e) => {

        const washbasinInput = document.querySelector("#washbasinInput")
        const dispenser = document.querySelector("#dispenserInput")

        if (e.target === washbasinInput) {
            setChecked({ ...checked, washbasin: !checked.washbasin })

            if (e.target.checked === true) {
                setOrder({ ...order, orderWashbasin: "tak" })
                setAccesoriesPrice(accesoriesPrice + 99)
            }

            if (e.target.checked === false) {
                setOrder({ ...order, orderWashbasin: "nie" })
                setAccesoriesPrice(accesoriesPrice - 99)
            }
        }

        if (e.target === dispenser) {
            setChecked({ ...checked, dispenser: !checked.dispenser })

            if (e.target.checked === true) {
                setOrder({ ...order, orderDispenser: "tak" })
                setAccesoriesPrice(accesoriesPrice + 59)
            }

            if (e.target.checked === false) {
                setOrder({ ...order, orderDispenser: "nie" })
                setAccesoriesPrice(accesoriesPrice - 59)
            }
        }
    }

    const inputChecked = <BsCheckSquareFill size='50px' />
    const inputNotChecked = <BsCheckSquare size='50px' />

    const fusionGallery = [
        {
            image: FusionCabin.Fusion1
        },
        {
            image: FusionCabin.Fusion2
        },
        {
            image: FusionCabin.Fusion3
        },
        {
            image: FusionCabin.Fusion4
        }
    ]

    const fusionCarousel = <Carousel
        data={fusionGallery}
        time={2000}
        width="95%"
        captionPosition="bottom"
        automatic={true}
        dots={false}
        pauseIconColor="white"
        pauseIconSize="0"
        slideBackgroundColor="none"
        slideImageFit="cover"
        thumbnails={true}
        thumbnailWidth="100px"
    />

    const mtmGallery = [
        {
            image: MtmCabin.Mtm1
        },
        {
            image: MtmCabin.Mtm2
        },
        {
            image: MtmCabin.Mtm3
        },
        {
            image: MtmCabin.Mtm4
        }
    ]

    const mtmCarousel = <Carousel
        data={mtmGallery}
        time={2000}
        width="95%"
        captionPosition="bottom"
        automatic={true}
        dots={false}
        pauseIconColor="white"
        pauseIconSize="0"
        slideBackgroundColor="none"
        slideImageFit="cover"
        thumbnails={true}
        thumbnailWidth="100px"
    />

    const chooseService = (e) => {
        if (e.target === document.querySelector("#oneWeekInput") && checked.oneWeekInput !== true) {
            setChecked({ ...checked, serviceOneWeek: true, serviceTwoWeek: false, serviceFourWeek: false })
            setOrder({ ...order, orderService: 'Co tydzień' })
            setServicePrice(399)
        }

        if (e.target === document.querySelector("#twoWeekInput") && checked.serviceTwoWeek !== true) {
            setChecked({ ...checked, serviceTwoWeek: true, serviceOneWeek: false, serviceFourWeek: false })
            setOrder({ ...order, orderService: 'Co 2 tygodnie' })
            setServicePrice(299)
        }

        if (e.target === document.querySelector("#fourWeekInput") && checked.serviceFourWeek !== true) {
            setChecked({ ...checked, serviceFourWeek: true, serviceOneWeek: false, serviceTwoWeek: false })
            setOrder({ ...order, orderService: 'Co 4 tygodnie' })
            setServicePrice(216)
        }
    }

    const plusIcon = <FiPlus size='30px' className="plusIcon" />
    const minusIcon = <FiMinus size='30px' className="minusIcon" />
    const shopIcon = <IoStorefront size='60px' />
    const bascetcase = <MdShoppingCart size='60px' />

    const changeSubmit = (e) => {
        e.preventDefault()
        setPage((currPage) => currPage + 1)
        setOrder((order) => ({
            ...order, orderPrice: orderPrice(), orderStartingDate: orderStartDate, orderEndingDate: orderEndDate
        }))
    }

    let price

    const orderPrice = () => {
        if (difference > 0 || difference < 7) {
            price = ((199 + accesoriesPrice) * order.cabinsValue).toFixed(2)
        }

        if (difference > 6) {
            price = ((accesoriesPrice + servicePrice) * order.cabinsValue).toFixed(2)
        }

        return price
    }

    return (

        <form className="form__formCabins--wrapper" onSubmit={changeSubmit}>
            <div className="form__steps--first">
                <div></div>
                <div>
                    <p>KROK 1</p>
                    <p>Zamówienie <br />i specyfikacja kabiny</p>
                </div>
                <div>
                    <p>KROK 2</p>
                    <p>Uzupełnij <br />swoje dane</p></div>
                <div>
                    <p>KROK 3</p>
                    <p>Podsumowanie <br />sprawdź informacje</p></div>
                <div>
                    <p>KROK 4</p>
                    <p>Potwierdzenie <br />Twojego zamówienia</p></div>
                <div></div>
            </div>
            <div className="formCabins--form">
                <h3>DOSTĘPNE</h3>
                <div className="formCabins--cabinTypes">
                    <div className="formCabins--chooseType">
                        <div style={{ height: checked.fusionInput === true ? "120px" : "90px" }}>
                            <div className="fusionContainer--filter"
                                style={{ display: checked.fusionInput === true ? "none" : "block" }}></div>
                            <input
                                type="radio"
                                id="fusionInput"
                                className="chooseType__input"
                                name="setCabinType"
                                value={checked.fusionInput}
                                onChange={chooseToiletType}
                            />
                            {checked.fusionInput ? inputChecked : inputNotChecked}
                            <label>KABINA </label>
                        </div>
                        <div style={{ height: checked.mtmInput === true ? "120px" : "90px" }}>
                            <div className="mtmContainer--filter"
                                style={{ display: checked.mtmInput === true ? "none" : "block" }}></div>
                            <input
                                type="radio"
                                id="mtmInput"
                                className="chooseType__input"
                                name="setCabinType"
                                value={checked.mtmInput}
                                onChange={chooseToiletType}
                            />
                            {checked.mtmInput ? inputChecked : inputNotChecked}
                            <label>KABINA </label>
                        </div>
                    </div>
                    <div className="formCabins__Info--wrapper"
                        style={{ display: checked.fusionInput === true ? "flex" : "none" }}>
                        <div className="formCabins__Info--carousel">
                            {checked.fusionInput ? fusionCarousel : null}
                        </div>
                        <div className="formCabins__Info--description">
                            <h3>WYPOSAŻENIE PODSTAWOWE</h3>
                            <p><br />drzwi o profilu zamkniętym,<br />
                                półprzezroczysty dach<br />
                                pisuar,<br />
                                zamek wolne/zajęte,<br />
                                antypoślizgowa podłoga,<br />
                                dwa systemy wentylacji,<br />
                                uchwyt na papier toaletowy,<br />
                                zbiornik o pojemności 265 l (ok…700 wizyt),<br />
                                deska sedesowa.</p>

                            <h3><br />WYPOSAŻENIE DODATKOWE</h3>

                            <p><br />umywalka ze zbiornikiem na wodę,<br />
                                dozownik środka dezynfekującego,<br />
                                podajnik papierowych ręczników,<br />
                                oświetlenie,<br />
                                kosz na śmieci,<br />
                                uchwyty do podnoszenie kabiny dźwigiem,<br />
                                kłódka zewnętrzna.<br /></p>

                            <h3><br />DANE TECHNICZNE</h3>

                            <p><br />wysokość 2290 mm<br />
                                szerokość 1100 mm<br />
                                głębokość 1200 mm<br />
                                waga 80 kg</p>
                        </div>
                    </div>
                    <div className="formCabins__Info--wrapper"
                        style={{ display: checked.mtmInput === true ? "flex" : "none" }}>
                        <div className="formCabins__Info--carousel">
                            {checked.mtmInput ? mtmCarousel : null}
                        </div>
                        <div className="formCabins__Info--description">
                            <h3>WYPOSAŻENIE PODSTAWOWE</h3>
                            <p><br />drzwi o profilu zamkniętym,<br />
                                półprzezroczysty dach<br />
                                pisuar,<br />
                                zamek wolne/zajęte,<br />
                                antypoślizgowa podłoga,<br />
                                dwa systemy wentylacji,<br />
                                uchwyt na papier toaletowy,<br />
                                zbiornik o pojemności 265 l (ok…700 wizyt),<br />
                                deska sedesowa.</p>

                            <h3><br />WYPOSAŻENIE DODATKOWE</h3>

                            <p><br />umywalka ze zbiornikiem na wodę,<br />
                                dozownik środka dezynfekującego,<br />
                                podajnik papierowych ręczników,<br />
                                oświetlenie,<br />
                                kosz na śmieci,<br />
                                uchwyty do podnoszenie kabiny dźwigiem,<br />
                                kłódka zewnętrzna.<br /></p>

                            <h3><br />DANE TECHNICZNE</h3>

                            <p><br />wysokość 2290 mm<br />
                                szerokość 1100 mm<br />
                                głębokość 1200 mm<br />
                                waga 77,5 kg</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='cabinsForm--placeAndDate--wrapper'>
                <div className='cabinsForm--place'>
                    <h3>ILOŚĆ </h3>
                    <input
                        type="number"
                        id="cabinsValueInput"
                        placeholder='Podaj ilość kabin'
                        min={1}
                        value={order.cabinsValue}
                        onChange={(e) => {
                            setOrder({ ...order, cabinsValue: e.target.value })
                        }}
                        required
                    />
                    <h3>MIEJSCE </h3>
                    <div>
                        <div>
                            <input
                                type="text"
                                placeholder="Miejscowość"
                                value={order.orderCity}
                                onChange={(e) => {
                                    setOrder({ ...order, orderCity: e.target.value })
                                }}
                                required
                            />
                            <InputMask
                                type="text"
                                placeholder="Kod pocztowy"
                                max={6}
                                value={order.orderPostcode}
                                onChange={(e) => {
                                    setOrder({ ...order, orderPostcode: e.target.value })
                                }}
                                mask="99-999"
                                required
                            />
                        </div>
                        <div>
                            <input
                                type="text"
                                placeholder="Ulica"
                                value={order.orderStreet}
                                onChange={(e) => {
                                    setOrder({ ...order, orderStreet: e.target.value })
                                }}
                                required
                            />
                            <input
                                type="text"
                                placeholder="Nr"
                                value={order.orderPlaceNr}
                                onChange={(e) => {
                                    setOrder({ ...order, orderPlaceNr: e.target.value })
                                }}
                                required
                            />
                        </div>
                        <input
                            className="input--phone"
                            type="text"
                            placeholder="Numer kontaktowy osoby odbierającej toalety"
                            value={order.orderContatNumber}
                            onChange={(e) => {
                                setOrder({ ...order, orderContatNumber: e.target.value })
                            }}
                            minLength='9'
                            maxLength='9'
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            required
                        />
                    </div>
                </div>
                <div className='cabinsForm--date'>
                    <h3>OKRES WYNAJĘCIA </h3>
                    <div className='cabinsForm__date--wrapper'>
                        <div>
                            <h4>SUGEROWANA DATA USTAWIENIA:</h4>
                            <Calendar
                                minDate={dateNow}
                                value={startDate}
                                onChange={setStartDate}
                            />
                        </div>
                        <div>
                            <h4>SUGEROWANA DATA ZABRANIA:</h4>
                            <Calendar
                                minDate={startDate}
                                maxDate={(addDays(new Date(startDate), 27))}
                                onChange={setEndDate}
                                value={endDate}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="cabinsForm__serviceAndPrice--wrapper">
                <div className="cabinsForm__serviceAndPrice--service">
                    <h3>SERWIS:</h3>
                    <p>Jak często mamy opróżniać i myć toaletę?</p>
                    <div className="cabinsForm__service--inputs"
                        style={{ display: difference >= 7 ? "flex" : "none" }}>
                        <div className="cabinsForm__service--input">
                            <img src={Images.ServiceFourWeekWhite} alt="ServiceOneWeekWhite"
                                style={{ display: checked.serviceFourWeek ? "none" : "block" }}
                            />
                            <img src={Images.ServiceFourWeekBlue} alt="ServiceOneWeekBlue"
                                style={{ display: checked.serviceFourWeek ? "block" : "none" }}
                            />
                            <input
                                type="radio"
                                name="chooseService"
                                id="fourWeekInput"
                                onChange={chooseService}
                            />
                        </div>
                        <div className="cabinsForm__service--input">
                            <img src={Images.ServiceTwoWeekWhite} alt="ServiceTwoWeekWhite"
                                style={{ display: checked.serviceTwoWeek ? "none" : "block" }}
                            />
                            <img src={Images.ServiceTwoWeekBlue} alt="ServiceTwoWeekBlue"
                                style={{ display: checked.serviceTwoWeek ? "block" : "none" }}
                            />
                            <input
                                type="radio"
                                name="chooseService"
                                id="twoWeekInput"
                                onChange={chooseService}
                            />
                        </div>
                        <div className="cabinsForm__service--input">
                            <img src={Images.ServiceOneWeekWhite} alt="ServiceOneWeekWhite"
                                style={{ display: checked.serviceOneWeek ? "none" : "block" }}
                            />
                            <img src={Images.ServiceOneWeekBlue} alt="ServiceOneWeekBlue"
                                style={{ display: checked.serviceOneWeek ? "block" : "none" }}
                            />
                            <input
                                type="radio"
                                name="chooseService"
                                id="oneWeekInput"
                                onChange={chooseService}
                            />
                        </div>
                    </div>
                    <div className="cabinsForm__service--inputs"
                        style={{ display: difference <= 6 ? "flex" : "none" }}>
                        <div className="cabinsForm__service--input">
                            <img src={Images.ServiceFourWeekGrey} alt="ServiceFourWeekGrey" />
                        </div>
                        <div className="cabinsForm__service--input">
                            <img src={Images.ServiceTwoWeekGrey} alt="ServiceTwoWeekGrey" />
                        </div>
                        <div className="cabinsForm__service--input">
                            <img src={Images.ServiceOneWeekGrey} alt="ServiceOneWeekGrey" />
                        </div>
                    </div>
                    <div className="cabinsForm__accesories--wrapper">
                        <h3>AKCESORIA </h3>
                        <div className="cabinsForm__accesories--item">
                            <div className="cabinsForm__accesories--input"
                                style={{ background: checked.washbasin === true ? "var(--red)" : "var(--greyGradient)" }}
                            >
                                {checked.washbasin ? plusIcon : minusIcon}
                                <input
                                    type='checkbox'
                                    id='washbasinInput'
                                    onClick={chooseAccesories}
                                />
                            </div>
                            <label> Umywalka <b>+ 99.00 zł</b></label>
                        </div>
                        <div className="cabinsForm__accesories--item">
                            <div className="cabinsForm__accesories--input"
                                style={{ background: checked.dispenser === true ? "var(--red)" : "var(--greyGradient)" }}
                            >
                                {checked.dispenser ? plusIcon : minusIcon}
                                <input
                                    type='checkbox'
                                    id='dispenserInput'
                                    onClick={chooseAccesories}
                                />
                            </div>
                            <label> Dozownik płynu dezynfekującego <b>+ 59.00 zł</b></label>
                        </div>
                    </div>
                </div>
                <div className="cabinsForm__price--wrapper">
                    <div className="cabinsForm__price--icon">
                        {shopIcon}
                    </div>
                    <h3>CENA </h3>
                    <div className="cabinsForm__price--price">
                        <div>{bascetcase}</div>
                        <div>
                            <p>{orderPrice()}</p>
                            <p>zł brutto</p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
            </div>
            <div className='next__button--wrapper'>
                <p>krok: 1 z 4</p>
                <button type='submit' className="order--nextButton">DALEJ</button>
            </div>
        </form>
    )
}

export default FormCabins