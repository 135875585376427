import React, { useEffect } from 'react'
import '../styles/about.css'
import { Images } from '../assets/images/index.js'
import Header from './Header'
import Navigation from './Navigation'
import Copyright from './Copyright'
import Footer from './Footer'
import Benefits from './Benefits'
import Localizations from './Localizations'

const About = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    })

    return (
        <div className="about--wrapper">
            <Header />
            <Navigation />
            <div className="about--baner">
                <img src={Images.Top_baner} alt="top_baner"></img>
                <div className="about--baner__filter">
                    <h1>TOALETY</h1>
                </div>
            </div>
            <div className="about--mainContent about--firstDescr about__mainContent--after">
                <p>SERWISTOALET.PL to profesjonalna firma, której podstawową działalnością jest wynajem i serwis przenośnych toalet typu toi-toi oraz wysokiej jakości usługi asenizacyjne. Nasza oferta skierowana jest zarówno do osób indywidualnych, jak i podmiotów instytucjonalnych, które cenią najwyższy standard obsługi. Od lat stawiamy na solidność i terminowość oraz elastyczne dopasowywanie się do potrzeb naszych kontrahentów, dzięki czemu grono zadowolonych klientów naszej firmy sukcesywnie się powiększa. Dynamiczny rozwój marki SERWISTOALET.PL pozwala nam stale rozbudowywać i udoskonalać ofertę oraz poszerzać zasięg terytorialny naszej aktywności, która obejmuje obecnie województwo warmińsko-mazurskie i pomorskie.</p>
                <p>W ofercie firmy SERWISTOALET.PL znajdują się wyłącznie solidne, stabilne i funkcjonalne toalety przenośne, z których można korzystać w każdych warunkach atmosferycznych, bez względu na porę roku. Niezależnie od tego, nasze przenośne kabiny WC charakteryzują się wysoką estetyką wykonania i posiadają kompaktowe wymiary, przez co z łatwością można dopasować je do każdej przestrzeni. </p>
                <p>W ramach pełnego pakietu świadczonych przez nas usług, znajduje się dostawa, bieżące utrzymanie toalet przenośnych w sprawności i czystości oraz odbiór kabin WC po zakończeniu umownego wynajmu. Serwis kabin przenośnych typu toi-toi obejmuje m.in. okresowe opróżnianie zbiornika oraz jego mycie, dezynfekcję i uzupełnianie materiałów eksploatacyjnych. </p>
                <p>Oprócz wynajmu i serwisu mobilnych kabin WC, w ostatnim czasie poszerzyliśmy naszą ofertę również o kompleksowe usługi asenizacyjne. Z oferty tej mogą skorzystać m.in. posiadacze przydomowego szamba, zbiornika bezodpływowego czy ekologicznej oczyszczalni ścieków. W razie potrzeby, świadczymy też usługi interwencyjne, związane z wypompowaniem wody, np. po zalaniu czy lokalnym podtopieniu.</p>
                <p>W odpowiedzi na wyzwania rynku oraz rosnące oczekiwania ze strony klientów, gwarantujemy ekspresowy czas realizacji zamówień (do 12 h), zapewniamy konkurencyjne ceny, a okres wynajmu dopasowujemy elastycznie do indywidualnych potrzeb. Bogate doświadczenie, odpowiednia infrastruktura, zróżnicowany wachlarz usług, rzetelna realizacja zamówień i wysoka jakość obsługi – to znak rozpoznawczy marki SERWISTOALET.PL. </p>
            </div>
            <div className="about--mainContent about--secDescr about__mainContent--after">
                <p>Cieszymy się zaufaniem tysięcy stałych klientów, wśród których nie brakuje dużych firm, instytucji i urzędów. Regularnie startujemy w przetargach publicznych (współpraca m.in. z Pruszczem Gdańskim) oraz obsługujemy prestiżowe przedsięwzięcia/inwestycje (np. przekop Mierzei Wiślanej). Nasze mobilne toalety przenośne doskonale sprawdzają się w roli infrastruktury uzupełniającej w takich lokalizacjach, jak:</p>
                <div className="about__mainContent--list">
                    <div className="about__list--line">
                        <img src={Images.LiIcon} alt="LiIcon"/>
                        <p>PLACE BUDOWY,</p>
                    </div>
                    <div className="about__list--line">
                        <img src={Images.LiIcon} alt="LiIcon"/>
                        <p>IMPREZY MASOWE (NP. FESTYNY, KONCERTY, WYDARZENIA SPORTOWE, OBCHODY ROCZNICOWE),</p>
                    </div>
                    <div className="about__list--line">
                        <img src={Images.LiIcon} alt="LiIcon"/>
                        <p>MIEJSCA ZBIOROWEGO WYPOCZYNKU (BIWAKI, KEMPINGI, OBOZY, KOLONIE, POLA NAMIOTOWE),</p>
                    </div>
                    <div className="about__list--line">
                        <img src={Images.LiIcon} alt="LiIcon"/>
                        <p>MIEJSCA W POBLIŻU ATRAKCJI TURYSTYCZNYCH,</p>
                    </div>
                    <div className="about__list--line">
                        <img src={Images.LiIcon} alt="LiIcon"/>
                        <p>MIEJSCA ORGANIZACJI PRYWATNYCH EVENTÓW W PLENERZE (IMPREZY FIRMOWE, OKOLICZNOŚCIOWE),</p>
                    </div>
                    <div className="about__list--line">
                        <img src={Images.LiIcon} alt="LiIcon"/>
                        <p>PARKI, PUBLICZNE PLACE ZABAW, PLENEROWE STREFY WORKOUT,</p>
                    </div>
                    <div className="about__list--line">
                        <img src={Images.LiIcon} alt="LiIcon"/>
                        <p>LOKALE I OGRÓDKI GASTRONOMICZNE,</p>
                    </div>
                    <div className="about__list--line">
                        <img src={Images.LiIcon} alt="LiIcon"/>
                        <p>PARKINGI.</p>
                    </div>
                </div>
                <p>Zapraszamy do współpracy osoby fizyczne, firmy i instytucje/urzędy – nie tylko z okolic Elbląga, ale również z pozostałych rejonów północnej Polski. Jeśli interesuje Cię krótko- lub długoterminowy wynajem przenośnych toalet – wystarczy telefon, mail bądź kontakt za pośrednictwem udostępnionego na stronie internetowej formularza. Specjalny, interaktywny formularz zamówienia – pozwoli nam szybko i precyzyjnie zidentyfikować Twoje potrzeby, zoptymalizować oraz dopasować do nich ofertę, a także przedstawić Ci atrakcyjną wycenę usługi.</p>
            </div>
            <div className="about__benefits--wrapper">
                <Benefits />
            </div>
            <div className="about--afterBenefits">
                <div className="about__afterBenefits--list">
                    <div className="about__list--line">
                        <img src={Images.LiIcon} alt="LiIcon"/>
                        <p>SOLIDNA KONSTRUKCJA</p>
                    </div>
                    <div className="about__list--line">
                        <img src={Images.LiIcon} alt="LiIcon"/>
                        <p>DRZWI O PROFILU PEŁNYM, ZAMYKANE OD ŚRODKA</p>
                    </div>
                    <div className="about__list--line">
                        <img src={Images.LiIcon} alt="LiIcon"/>
                        <p>PÓŁPRZEŹROCZYSTY DASZEK</p>
                    </div>
                    <div className="about__list--line">
                        <img src={Images.LiIcon} alt="LiIcon"/>
                        <p>PODWÓJNA WENTYLACJA</p>
                    </div>
                    <div className="about__list--line">
                        <img src={Images.LiIcon} alt="LiIcon"/>
                        <p>ANTYPOŚLIZGOWA PODŁOGA</p>
                    </div>
                    <div className="about__list--line">
                        <img src={Images.LiIcon} alt="LiIcon"/>
                        <p>ZBIORNIK BEZODPŁYWOWY O POJEMNOŚCI 265 L (OK. 700 WIZYT)</p>
                    </div>
                    <div className="about__list--line">
                        <img src={Images.LiIcon} alt="LiIcon"/>
                        <p>PISUAR</p>
                    </div>
                    <div className="about__list--line">
                        <img src={Images.LiIcon} alt="LiIcon"/>
                        <p>DESKA SEDESOWA</p>
                    </div>
                    <div className="about__list--line">
                        <img src={Images.LiIcon} alt="LiIcon"/>
                        <p>UCHWYT NA PAPIER TOALETOWY</p>
                    </div>
                    <div className="about__list--line">
                        <img src={Images.LiIcon} alt="LiIcon"/>
                        <p>MOCOWANIE NA KŁÓDKĘ – DO ZABEZPIECZENIA KABINY POZA UDOSTĘPNIANIEM</p>
                    </div>
                    <p>OPCJONALNIE:</p>
                    <div className="about__list--line">
                        <img src={Images.LiIcon} alt="LiIcon"/>
                        <p>UMYWALKA ZE ZBIORNIKIEM NA WODĘ</p>
                    </div>
                    <div className="about__list--line">
                        <img src={Images.LiIcon} alt="LiIcon"/>
                        <p>DOZOWNIK ŚRODKA DEZYNFEKUJĄCEGO</p>
                    </div>
                    <div className="about__list--line">
                        <img src={Images.LiIcon} alt="LiIcon"/>
                        <p>PODAJNIK PAPIEROWYCH RĘCZNIKÓW</p>
                    </div>
                    <div className="about__list--line">
                        <img src={Images.LiIcon} alt="LiIcon"/>
                        <p>OŚWIETLENIE</p>
                    </div>
                    <div className="about__list--line">
                        <img src={Images.LiIcon} alt="LiIcon"/>
                        <p>KOSZ NA ŚMIECI</p>
                    </div>
                    <div className="about__list--line">
                        <img src={Images.LiIcon} alt="LiIcon"/>
                        <p>PRZENOŚNE TOALETY DLA NIEPEŁNOSPRAWNYCH</p>
                    </div>
                </div>
            </div>
            <Footer />
            <Localizations />
            <Copyright />
        </div>
    )
}

export default About

