import React, { useEffect } from 'react'

const SanitationEnding = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    },[])

    return (
        <div className="formEnding--wrapper">
            <div className="form__steps--fourth">
                <div></div>
                <div>
                    <p>KROK 1</p>
                    <p>Zamówienie</p>
                </div>
                <div>
                    <p>KROK 2</p>
                    <p>Uzupełnij <br />swoje dane</p></div>
                <div>
                    <p>KROK 3</p>
                    <p>Podsumowanie <br />sprawdź informacje</p></div>
                <div>
                    <p>KROK 4</p>
                    <p>Potwierdzenie <br />Twojego zamówienia</p></div>
                <div></div>
            </div>
            <div className="formEnding__content--wrapper">
                <div className="formEnding--content">
                    <p>Dziękujemy za złożenie zamówienia.</p>
                </div>
                <p>Na Twój adres email została wysłana kopia zamówienia.</p>
            </div>
        </div>
    )
}

export default SanitationEnding