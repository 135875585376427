import React, {useEffect} from "react"
import '../styles/home.css'
import { Images } from '../assets/images/index.js'
import Header from './Header'
import Navigation from './Navigation'
import Copyright from './Copyright'
import Footer from './Footer'
import Benefits from './Benefits'
import { Link } from 'react-router-dom'
import Localizations from './Localizations'

const Home = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    })

    return (
        <div className="home--wrapper">
        <Header />
        <Navigation />
        <div className="home__mainConent">
            <div className="home__slider--wrapper">
                <div className="home__slider--imgHolder">
                    <img src={Images.Slider} alt="slider" />
                </div>
                <div className="home__slider--filtr"></div>
            </div>
            <div className="home__services--mainWrapper">
                <div className="home__services--wrapper">
                    <div className="home__services--toilets">
                        <div className="home__toilets--header">
                            <div className="home__toilets--imgHolder">
                                <img src={Images.Toilets} alt="toilets" />
                            </div>
                            <div className="home__toilets--title">
                                <h1>TOALETY</h1>
                                <h1>DO WYNAJĘCIA</h1>
                            </div>
                        </div>
                        <div className="home__toilets--description">
                            <p><b>SERWISTOALET.PL</b> to profesjonalna firma, której podstawową działalnością jest <b>wynajem i serwis przenośnych toalet typu toi-toi</b> oraz wysokiej jakości <b>usługi asenizacyjne</b>. Nasza oferta skierowana jest zarówno do osób indywidualnych, jak i podmiotów instytucjonalnych, które cenią <b>najwyższy standard obsługi...</b> </p>
                            <div className="home__toilets--linkHolder">
                                <Link to='/about'>CZYTAJ WIĘCEJ</Link>
                            </div>
                        </div>
                    </div>
                    <div className="home__services--sanitation">
                        <div className="home__sanitation--header">
                            <div className="home__sanitation--imgHolder">
                                <img src={Images.Truck} alt="sanitation" />
                            </div>
                            <div className="home__sanitation--title">
                                <h1>USŁUGI</h1>
                                <h1>ASENIZACYJNE</h1>
                            </div>
                        </div>
                        <div className="home__sanitation--description">
                            <p>Firma SERWISTOALET.PL świadczy <b>kompleksowe usługi asenizacyjne</b>, takie jak <b>wywóz i utylizacja nieczystości płynnych – bytowych oraz przemysłowych</b>. Dysponujemy specjalistycznymi pojazdami, sprzętem oraz wykwalifikowanymi pracownikami do oczyszczania i wywozu ścieków z takich instalacji...</p>
                            <div className="home__sanitation--linkHolder">
                                <Link to='/offert/sanitation'>CZYTAJ WIĘCEJ</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="home__benefits--wrapper">
                <Benefits />
            </div>
        </div>
        <Footer />
        <Localizations />
        <Copyright />
    </div>

    )


}

export default Home