import React, { useEffect } from 'react'
import '../../styles/localizations_city.css';
import { Images, Cities } from '../../assets/images/index.js'
import Header from '../Header'
import Navigation from '../Navigation'
import Copyright from '../Copyright'
import Footer from '../Footer'
import Benefits from '../Benefits'
import Localizations from '../Localizations'
import OrderRedBaner from '../Misc/Order_red_baner'

const Malbork = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    })

    return (
        <div className="city--wrapper">
            <Header />
            <Navigation />
            <div className="city--baner">
                <img src={Images.Top_baner} alt="top_baner"></img>
                <div className="city--baner__filter">
                    <h1>SERWIS</h1>
                    <h2>MALBORK</h2>
                </div>
            </div>
            <div className="city--mainContent">
                <div className="city--imgWrapper">
                    <img src={Cities.MalborkCity} alt='party_img' />
                    <div className="city--red_baner">
                        <OrderRedBaner />
                    </div>
                </div>
                <div className="city--description">
                    <h3>Ergonomiczne toalety przenośne – Malbork i okolice</h3>
                    <p>Serwistoalet.pl od lat wspiera firmy z województwa pomorskiego w organizacji mobilnego zaplecza sanitarnego, dostarczając uniwersalne toalety przenośne. Malbork i okolice od lat należą do obszarów naszej aktywności. Oferujemy korzystny krótko- lub długookresowy wynajem toalet przenośnych na terenie Malborka – zarówno dla odbiorców indywidualnych, przedsiębiorstw budowlanych, eventowych i instytucji, gwarantując przy tym solidną jakość usług oraz bardzo okazyjne ceny.</p>
                    <p>Na stronie serwistoalet.pl udostępniamy prosty formularz online do składania zamówień, które realizujemy bez zbędnej zwłoki (max do 12 h). Ergonomiczne przenośne toalety sprawdzą się przede wszystkim tam, gdzie nie ma swobodnego dostępu do stacjonarnego zaplecza sanitarnego i/lub sieci wod-kan. Z przyjemnością dostarczymy przenośne kabiny WC pod wskazany adres i w ustalonym terminie, zapewniając jednocześnie profesjonalny serwis w trakcie trwania wynajmu. Obsługujemy cały Malbork, w tym m.in. takie dzielnice, jak: Śródmieście, Czwartaki, Małe Piaski, Południe, Stare Miasto czy Nową Wieś.</p>
                    <h3>Gdzie przydadzą się toalety przenośne w Malborku?</h3>
                    <div className="city__mainContent--list">
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt='party_img'/>
                            <p>PLACE BUDOWY,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt='party_img'/>
                            <p>IMPREZY MASOWE (NP. FESTYNY, KONCERTY, WYDARZENIA SPORTOWE, OBCHODY ROCZNICOWE),</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt='party_img'/>
                            <p>MIEJSCA ZBIOROWEGO WYPOCZYNKU (BIWAKI, KEMPINGI, OBOZY, KOLONIE, POLA NAMIOTOWE),</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt='party_img'/>
                            <p>MIEJSCA W POBLIŻU ATRAKCJI TURYSTYCZNYCH,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt='party_img'/>
                            <p>MIEJSCA ORGANIZACJI PRYWATNYCH EVENTÓW W PLENERZE (IMPREZY FIRMOWE, OKOLICZNOŚCIOWE),</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt='party_img'/>
                            <p>PARKI, PUBLICZNE PLACE ZABAW, PLENEROWE STREFY WORKOUT,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt='party_img'/>
                            <p>LOKALE I OGRÓDKI GASTRONOMICZNE,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt='party_img'/>
                            <p>OGRÓDKI DZIAŁKOWE,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt='party_img'/>
                            <p>PARKINGI.</p>
                        </div>
                    </div>
                    <h3>Mobilne toalety (Malbork) – oferta dopasowana do oczekiwań</h3>
                    <p>Mobilne toalety wynajmowane przez firmę Serwistoalet.pl, mają solidną konstrukcję, cechuje je nowoczesny design, a poza tym – można je montować na wszystkich rodzajach podłoża (asfalt/beton, ziemne, trawiaste; w razie potrzeby możliwe jest kotwienie). Toalety przenośne na budowy, do parków czy do obsługi imprez w Malborku – wytworzone zostały z wysokiej klasy materiałów, które są bardzo odporne na działanie niekorzystnych czynników atmosferycznych (opady deszczu/śniegu, silne podmuchy wiatru, mrozy, upały czy intensywne nasłonecznienie/UV). </p>
                    <p>Ubikacje mobilne posiadają systemy wentylacyjne, antypoślizgową podłogę, zamykane od środka pełne drzwi, półprzeźroczyste sklepienie oraz zbiornik bezodpływowy o pojemności 265 l (do ok. 700 wizyt). Przenośne toalety mogą zostać wyposażone dodatkowo w umywalkę ze zbiornikiem i zapasem wody, deskę sedesową, pisuar oraz dozownik ze środkiem myjącym do rąk.</p>
                    <h3>Zapraszamy do współpracy firmy, instytucje, urzędy i osoby fizyczne z całego Malborka!</h3>
                </div>
            </div>
            <div className="city__benefits--wrapper">
                <Benefits />
            </div>
            <div className="city__last_red_baner--wrapper">
                <div>
                    <OrderRedBaner />
                </div>
            </div>
            <Footer />
            <Localizations />
            <Copyright />
        </div>

    )
}

export default Malbork