import React from 'react'
import '../styles/copyright.css'

const Copyright = () => {



    return (
        <div className="copyright--wrapper">
            <p>Copyright {new Date().getFullYear()} &copy;</p>
            <h1>Serwis</h1>
            <p>Wszystkie prawa zastrzeżone.</p>
        </div>
    )

}

export default Copyright