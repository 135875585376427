import '../../../styles/map.css';
import { 
    GoogleMap,
    Marker,
    Circle,
    Autocomplete
} from '@react-google-maps/api'

const SanitationMap = ({sanitationOrder, setSanitationOrder}) => {

    return <div className="map">

    
        <GoogleMap 
            zoom={13}
            center={{ lat: 54.190420, lng: 19.387880 }}
            mapContainerClassName="mapContainer--container"
            options={{ 
                gestureHandling: 'cooperative', 
                disableDefaultUI: true,
                mapTypeControl: false,
                fullscreenControl: false,
                zoomControl: false
            }}
        >
        <Marker 
            position={{ lat: 54.190420, lng: 19.387880 }}
            title={'SerwisToalet.pl'}
        />

        <Circle 
            center={{ lat: 54.190420, lng: 19.387880 }}
            radius={3000}
        />

        <Autocomplete>
            <input
            id='autocomplete'
            type="text"
            placeholder="napisaj coś"
            style={{
                boxSizing: `border-box`,
                border: `1px solid transparent`,
                width: `240px`,
                height: `32px`,
                padding: `0 12px`,
                borderRadius: `3px`,
                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                fontSize: `14px`,
                outline: `none`,
                textOverflow: `ellipses`,
                position: "absolute",
                left: "50%",
                marginLeft: "-120px"
              }}
            />
        </Autocomplete>


        </GoogleMap>
    </div>
}
 
export default SanitationMap