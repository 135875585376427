import React, { useEffect } from 'react'
import '../../styles/localizations_city.css';
import { Images } from '../../assets/images/index.js'
import Header from '../Header'
import Navigation from '../Navigation'
import Copyright from '../Copyright'
import Footer from '../Footer'
import Benefits from '../Benefits'
import Localizations from '../Localizations'
import OrderRedBaner from '../Misc/Order_red_baner'

const Prgdanski = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    })

    return (
        <div className="city--wrapper">
            <Header />
            <Navigation />
            <div className="city--baner">
                <img src={Images.Top_baner} alt="top_baner"></img>
                <div className="city--baner__filter">
                    <h1>SERWIS</h1>
                    <h2>PRUSZCZ GDAŃSKI</h2>
                </div>
            </div>
            <div className="city--mainContent">
                <div className="city--imgWrapper">
                    <img src={Images.PartyImg} alt='party_img' />
                    <div className="city--red_baner">
                        <OrderRedBaner />
                    </div>
                </div>
                <div className="city--description">
                    <h3>Pojemne toalety przenośne – Pruszcz Gdański i okolice</h3>
                    <p>Przedsiębiorstwo Serwistoalet.pl z siedzibą w Elblągu od wielu lat świadczy wysokiej jakości usługi asenizacyjne oraz oferuje na wynajem krótko i długoterminowy komfortowe toalety przenośne. Pruszcz Gdański jest jedną z wielu miejscowości w województwie pomorskim, w którym nasza firma cieszy się uznaniem i posiadamy rzeszę zadowolonych odbiorców (osoby prywatne, przedsiębiorcy, instytucje itd.). W ubiegłym roku, Urząd w Pruszczu Gdańskim, zlecił nam kompleksową obsługę , związaną z dostarczeniem i serwisem przenośnych toalet na terenie miasta. Serwistoalet.pl to firma, dla której najistotniejszy jest  wysoki poziom świadczonych usług i zadowolenie klientów. Oferujemy konkurencyjne warunki cenowe i rabaty dla stałych partnerów biznesowych naszej firmy.</p>
                    <p>Na naszej stronie dostępny jest intuicyjny formularz online do składania zamówień, który pomaga w identyfikowaniu i skalowaniu potrzeb oraz przygotowaniu optymalnej oferty. Wszystkie zamówienia przyjmujemy w cyklu całodobowym (24/7), z terminem realizacji zlecenia najpóźniej do 12h. Kabiny WC w Pruszczu Gdańskim dowozimy pod wskazany adres, zapewniamy bezpieczny rozładunek i montaż oraz kompleksowy serwis w okresie obowiązywania umowy wynajmu na wolnostojące toalety. Serwis to regularne czyszczenie, dezynfekowanie oraz uzupełnianie środków eksploatacyjnych (eliminujących fetor) i materiałów higienicznych (papier toaletowy, mydło). Profesjonalne usługi Serwistoalet.pl dostępne są na obszarze całego Pruszcza Gdańskiego, obejmując swym zasięgiem takie dzielnice miasta, jak np. Bałtycka Strefa Inwestycyjna.</p>
                    <h3>Gdzie przydadzą się toalety przenośne w Pruszczu Gdańskim?</h3>
                    <div className="city__mainContent--list">
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>PLACE BUDOWY,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>IMPREZY MASOWE (NP. FESTYNY, KONCERTY, WYDARZENIA SPORTOWE, OBCHODY ROCZNICOWE),</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>MIEJSCA ZBIOROWEGO WYPOCZYNKU (BIWAKI, KEMPINGI, OBOZY, KOLONIE, POLA NAMIOTOWE),</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>MIEJSCA W POBLIŻU ATRAKCJI TURYSTYCZNYCH,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>MIEJSCA ORGANIZACJI PRYWATNYCH EVENTÓW W PLENERZE (IMPREZY FIRMOWE, OKOLICZNOŚCIOWE),</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>PARKI, PUBLICZNE PLACE ZABAW, PLENEROWE STREFY WORKOUT,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>LOKALE I OGRÓDKI GASTRONOMICZNE,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>OGRÓDKI DZIAŁKOWE,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>PARKINGI.</p>
                        </div>
                    </div>
                    <h3>Przenośne toalety (Pruszcz Gdański) – oferta dopasowana do potrzeb</h3>
                    <p>Uniwersalne i na bieżąco serwisowane toalety są solidne i stabilne, idealnie nadają się na budowę, na event, do parku miejskiego, jak i na biwak czy na strefę parkingowa. Nasze przenośne toalety posiadają mocną konstrukcję, a kabiny są odporne na uszkodzenia mechaniczne, bez problemu można je również eksploatować całorocznie (materiały są odporne na wilgoć i ekstremalne wahania temperaturowe). </p>
                    <p>Wszystkie mobilne toalety wyposażone są w systemy wentylacyjne, a do zbiornika bezodpływowego aplikuje się specjalistyczne środki eliminujące powstawanie fetoru. Oprócz zamykanych od środka drzwi z oznaczeniem „wolne/zajęte”, sedesu, uchwytu na papier toaletowy czy antypoślizgowej wykładziny, nasze przenośne kabiny WC mogą być też doposażone opcjonalnie w umywalkę ze zbiornikiem wody (ok. 40 l) czy dozowniki na płynne środki dezynfekujące i ręczniki papierowe. W ofercie mamy także przenośne toalety dla niepełnosprawnych.</p>
                    <h3>Zapraszamy do współpracy firmy, instytucje, urzędy i osoby fizyczne z całego Pruszcza Gdańskiego!</h3>
                </div>
            </div>
            <div className="city__benefits--wrapper">
                <Benefits />
            </div>
            <div className="city__last_red_baner--wrapper">
                <div>
                    <OrderRedBaner />
                </div>
            </div>
            <Footer />
            <Localizations />
            <Copyright />
        </div>

    )
}

export default Prgdanski