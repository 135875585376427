import React, { useEffect } from 'react'
import '../../styles/localizations_city.css';
import { Images } from '../../assets/images/index.js'
import Header from '../Header'
import Navigation from '../Navigation'
import Copyright from '../Copyright'
import Footer from '../Footer'
import Benefits from '../Benefits'
import Localizations from '../Localizations'
import OrderRedBaner from '../Misc/Order_red_baner'

const Tczew = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    })

    return (
        <div className="city--wrapper">
            <Header />
            <Navigation />
            <div className="city--baner">
                <img src={Images.Top_baner} alt="top_baner"></img>
                <div className="city--baner__filter">
                    <h1>SERWIS</h1>
                    <h2>TCZEW</h2>
                </div>
            </div>
            <div className="city--mainContent">
                <div className="city--imgWrapper">
                    <img src={Images.PartyImg} alt='party_img' />
                    <div className="city--red_baner">
                        <OrderRedBaner />
                    </div>
                </div>
                <div className="city--description">
                    <h3>Profesjonalne toalety przenośne – Tczew i okolice</h3>
                    <p>Serwistoalet.pl już od wielu lat świadczy profesjonalne usługi, ukierunkowane głównie na wynajem toalet przenośnych i asenizację na terenie Pomorza (krótko- i długoterminowy wynajem mobilnych ubikacji). Z przenośnego zaplecza sanitarnego, na które składają się praktyczne toalety przenośne – w Tczewie – korzystają zarówno odbiorcy indywidualni, przedsiębiorstwa (np. firmy budowlane, eventowe, zarządcy publicznych stref rekreacyjnych), samorządy, jak i różne urzędy. Wszyscy nasi klienci mogą liczyć na fachową obsługę oraz niezwykle atrakcyjne ceny.</p>
                    <p>Zamówienia przyjmujemy za pośrednictwem e-formularza, dzięki któremu możemy precyzyjnie wyskalować potrzeby i przedstawić kompleksową ofertę/wycenę. Realizacja zamówienia nie przekracza 12 godzin od chwili, kiedy otrzymamy zgłoszenie zapotrzebowania. Dostarczamy toalety przenośne do wskazanej lokalizacji, instalujemy i obejmujemy je kompleksową obsługą serwisową. Wynajem i serwis mobilnych ubikacji to usługa skierowana do podmiotów działających na terenie całego Tczewa, w tym m.in. w takich dzielnicach, jak: Piotrowo, Prątnica, Suchostrzygi, Tczew-Wybudowanie, Prątnica-Siedlisko, Górki czy Malinowo.</p>
                    <h3>Gdzie przydadzą się toalety przenośne w Tczewie?</h3>
                    <div className="city__mainContent--list">
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>PLACE BUDOWY,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>IMPREZY MASOWE (NP. FESTYNY, KONCERTY, WYDARZENIA SPORTOWE, OBCHODY ROCZNICOWE),</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>MIEJSCA ZBIOROWEGO WYPOCZYNKU (BIWAKI, KEMPINGI, OBOZY, KOLONIE, POLA NAMIOTOWE),</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>MIEJSCA W POBLIŻU ATRAKCJI TURYSTYCZNYCH,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>MIEJSCA ORGANIZACJI PRYWATNYCH EVENTÓW W PLENERZE (IMPREZY FIRMOWE, OKOLICZNOŚCIOWE),</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>PARKI, PUBLICZNE PLACE ZABAW, PLENEROWE STREFY WORKOUT,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>LOKALE I OGRÓDKI GASTRONOMICZNE,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>OGRÓDKI DZIAŁKOWE,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>PARKINGI.</p>
                        </div>
                    </div>
                    <h3>Toalety mobilne (Tczew) – oferta dopasowana do indywidualnych potrzeb</h3>
                    <p>Toalety mobilne oferowane przez firmę Serwistoalet.pl – charakteryzuje stabilność, estetyka wykonania i funkcjonalność. Toalety przenośne (Tczew) cechuje podwyższona wytrzymałość na uszkodzenia mechaniczne, a tworzywa sztuczne, z jakich zostały wyprodukowane – są w 100% odporne na niekorzystne czynniki zewnętrzne (wilgoć, duże wahania temperatur itp.). Wszystko to sprawia, że nasze toalety mobilne mogą być z powodzeniem użytkowane o każdej porze roku.</p>
                    <p>Nasze przenośne toalety posiadają dwa efektywne systemy wentylacji. Klasyczne toalety przenośne, oprócz antypoślizgowej podłogi, drzwi o zamkniętym profilu z oznaczeniem wolne/zajęte, uchwytu na papier toaletowy oraz deski sedesowej, mogą zostać też wyposażone w umywalkę ze zasobnikiem wody i dozownik ze środkiem myjącym do dłoni.</p>
                    <h3>Zapraszamy do współpracy firmy, instytucje, urzędy i osoby fizyczne z całego Tczewa!</h3>
                </div>
            </div>
            <div className="city__benefits--wrapper">
                <Benefits />
            </div>
            <div className="city__last_red_baner--wrapper">
                <div>
                    <OrderRedBaner />
                </div>
            </div>
            <Footer />
            <Localizations />
            <Copyright />
        </div>

    )
}

export default Tczew