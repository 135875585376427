import React, { useEffect } from 'react'
import '../../styles/localizations_city.css';
import { Images } from '../../assets/images/index.js'
import Header from '../Header'
import Navigation from '../Navigation'
import Copyright from '../Copyright'
import Footer from '../Footer'
import Benefits from '../Benefits'
import Localizations from '../Localizations'
import OrderRedBaner from '../Misc/Order_red_baner'

const Gdynia = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    })

    return (
        <div className="city--wrapper">
            <Header />
            <Navigation />
            <div className="city--baner">
                <img src={Images.Top_baner} alt="top_baner"></img>
                <div className="city--baner__filter">
                    <h1>SERWIS</h1>
                    <h2>GDYNIA</h2>
                </div>
            </div>
            <div className="city--mainContent">
                <div className="city--imgWrapper">
                    <img src={Images.PartyImg} alt='party_img' />
                    <div className="city--red_baner">
                        <OrderRedBaner />
                    </div>
                </div>
                <div className="city--description">
                    <h3>Solidne toalety przenośne – Gdynia i okolice</h3>
                    <p>Serwistoalet.pl od wielu lat świadczy swoje usługi związane z wynajmem toalet przenośnych i asenizacją na terenie Pomorza (krótko- i długoterminowy wynajem kabin WC). Z mobilnego zaplecza sanitarnego, na jakie składają się funkcjonalne toalety przenośne – w Gdyni korzystają zarówno klienci indywidualni, przedsiębiorcy (np. firmy budowlane, eventowe), samorządy, jak i różne instytucje. Wszyscy nasi klienci mogą liczyć na najwyższą jakość obsługi oraz konkurencyjne ceny.</p>
                    <p>Zamówienia można składać za pośrednictwem interaktywnego formularza, który pozwala precyzyjnie wyskalować potrzeby i przygotować kompleksową ofertę. Realizacja każdego zamówienia nie przekracza 12 h od momentu przesłania stosownego zapotrzebowania. Dostarczamy toalety przenośne we wskazane miejsce, montujemy i obejmujemy je obsługą serwisową. Wynajem i serwis mobilnych toalet realizujemy na obszarze całej Gdyni, w tym m.in. w takich dzielnicach, jak: Chylonia, Dąbrowa, Obłuże, Oksywie, Podgórze, Śródmieście czy Cisowa.</p>
                    <h3>Gdzie przydadzą się toalety przenośne w Gdyni?</h3>
                    <div className="city__mainContent--list">
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="top_baner"/>
                            <p>PLACE BUDOWY,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="top_baner"/>
                            <p>IMPREZY MASOWE (NP. FESTYNY, KONCERTY, WYDARZENIA SPORTOWE, OBCHODY ROCZNICOWE),</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="top_baner"/>
                            <p>MIEJSCA ZBIOROWEGO WYPOCZYNKU (BIWAKI, KEMPINGI, OBOZY, KOLONIE, POLA NAMIOTOWE),</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="top_baner"/>
                            <p>MIEJSCA W POBLIŻU ATRAKCJI TURYSTYCZNYCH,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="top_baner"/>
                            <p>MIEJSCA ORGANIZACJI PRYWATNYCH EVENTÓW W PLENERZE (IMPREZY FIRMOWE, OKOLICZNOŚCIOWE),</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="top_baner"/>
                            <p>PARKI, PUBLICZNE PLACE ZABAW, PLENEROWE STREFY WORKOUT,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="top_baner"/>
                            <p>LOKALE I OGRÓDKI GASTRONOMICZNE,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="top_baner"/>
                            <p>OGRÓDKI DZIAŁKOWE,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="top_baner"/>
                            <p>PARKINGI.</p>
                        </div>
                    </div>
                    <h3>Mobilne kabiny WC (Gdynia) – oferta dopasowana do potrzeb</h3>
                    <p>Mobilne kabiny WC charakteryzują się stabilnością, estetyką wykonania i funkcjonalnością. Toalety przenośne (Gdynia) mają podwyższoną wytrzymałość na uszkodzenia mechaniczne, a tworzywa, z jakich zostały wykonane – są w pełni odporne na działanie niekorzystnych czynników pogodowych (wilgoć, wahania temperatur itp.). Nasze przenośne toalety posiadają dwa niezależne systemy wentylacyjne. Standardowe toalety przenośne, oprócz antypoślizgowej podłogi, drzwi o zamkniętym profilu czy sedesu, mogą zostać też wyposażone w umywalkę ze zbiornikiem wody i dozownik ze środkiem myjącym.</p>
                    <h3>Zapraszamy do współpracy firmy, instytucje, urzędy i osoby fizyczne z całej Gdyni!</h3>
                </div>
            </div>
            <div className="city__benefits--wrapper">
                <Benefits />
            </div>
            <div className="city__last_red_baner--wrapper">
                <div>
                    <OrderRedBaner />
                </div>
            </div>
            <Footer />
            <Localizations />
            <Copyright />
        </div>

    )
}

export default Gdynia