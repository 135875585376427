import React, {useEffect} from 'react'
import '../styles/offert.css'
import { Images } from '../assets/images/index.js'
import Header from './Header'
import Navigation from './Navigation'
import Copyright from './Copyright'
import Footer from './Footer'
import Benefits from './Benefits'
import OrderRedBaner from './Misc/Order_red_baner'
import Localizations from './Localizations'

const OffertOnBuildplace = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    })

    return (
        <div className="offert-wrapper">
        <Header />
        <Navigation />
        <div className="offert--baner">
            <img src={Images.Top_baner} alt="top_baner"></img>
            <div className="offert--baner__filter">
                <h1 className="offert--buildplaceTitle">TOALETY</h1>
            </div>
        </div>
        <div className="offert--mainContent">
            <div className="offert--imgWrapper">
                <img src={Images.BuildPlaceImg} alt='baner_buildplace' />
                <div className="offert--red_baner">
                    <OrderRedBaner />
                </div>
            </div>
            <div className="offert--description">
                <h3>Solidne toalety na budowę – tylko od Serwistoalet.pl!</h3>
                <p>Firma Serwistoalet.pl od lat dostarcza toalety na budowę, wspierając wykonawców inwestycji i remontów w organizacji odpowiedniego zaplecza sanitarnego. Warto podkreślić, że to na właścicielu firmy budowlanej spoczywa obowiązek stworzenia podległym pracownikom właściwych warunków higieniczno-sanitarnych, do których należy m.in. zapewnienie toalety na placu budowy. W przypadku prowadzenia frontu robót na terenie otwartym i brakiem dostępu do sieci wodno-kanalizacyjnej, optymalnym rozwiązaniem są przenośne kabiny WC na budowę, które oferujemy zarówno na krótko- oraz na długookresowy wynajem.  </p>
                <p>Toalety wolnostojące dostarczane przez Serwistoalet.pl charakteryzują się solidną konstrukcją i wykonane są z materiałów o podwyższonej wytrzymałości na mechaniczne uszkodzenia. Oprócz tego, mobilne toalety na budowę są odporne na działanie niesprzyjających czynników pogodowych, takich jak opady deszczu/śniegu, mrozy, wysokie temperatury i intensywne nasłonecznienie. Wszystko to sprawia, że solidne toalety doskonale nadają się na place budów oraz zachowują swoją pełną funkcjonalność niezależnie od pory roku i warunków atmosferycznych.</p>
                <p>Posiadamy specjalistyczne środki transportu i wykwalifikowanych operatorów, dzięki czemu dostarczymy dowolną liczbę przenośnych ubikacji na każdą budowę, bez względu na utrudnienia terenowe. Zapewniamy również kompleksowy serwis mobilnych kabin WC, przez co zawsze utrzymane są one w czystości i gotowości do użytkowania.</p>
                <h3>Wolnostojące, mobilne kabiny WC na placu budowy – zadbaj o przepisy i komfort pracowników</h3>
                <p>Zgodnie z obowiązującymi przepisami BHP, pracodawca zobowiązany jest do zapewnienia własnej ekipie budowlanej dostępu do toalety w odległości do 75 m od budynku lub 125 m od stanowiska pracy na otwartej przestrzeni. Przenośne toalety na budowie to najlepszy sposób na przestrzeganie przepisów i jednocześnie zagwarantowanie odpowiedniego komfortu pracownikom budowlanym. Nasze kabiny WC typu toi-toi są bardzo stabilne, posiadają dwa systemy wentylacji i inne środki eliminujące nieprzyjemne zapachy. W toaletach znajduje się ponadto antypoślizgowa podłoga, a opcjonalnie ubikacje mogą zostać wyposażone w umywalkę z pojemnym zbiornikiem na wodę.</p>
                <p>Serwistoalet.pl dostarcza przenośne toalety na place budowy, związane zarówno z modernizacją istniejących obiektów, wznoszeniem nowych budowli czy inwestycjami drogowymi. Nowoczesne ubikacje mobilne są zamykane/ryglowane od środka, ale posiadają też opcję zamknięcia kabiny na kłódkę – na wypadek konieczności ograniczenia dostępu z zewnątrz (np. po godzinach pracy).</p>
            </div>
        </div>
        <div className="offert__benefits--wrapper">
            <Benefits />
        </div>
        <div className="offert__last_red_baner--wrapper">
            <div>
                <OrderRedBaner />
            </div>
        </div>
        <Footer />
        <Localizations />
        <Copyright />
    </div>
    )
}

export default OffertOnBuildplace