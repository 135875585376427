import React, { useEffect } from "react"
import "../../styles/order.css"
import "../../styles/orderStyles/formSummary.css"
import "../../styles/orderStyles/formPersonalInfo.css"
import { BsCheckSquareFill } from "react-icons/bs"
import { MdShoppingCart } from "react-icons/md"
import emailjs from "@emailjs/browser"
import axios from 'axios'

const FormSummary = ({
  page,
  setPage,
  order,
  orderStartDate,
  orderEndDate,
  checked,
  clientType,
  contactData,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const inputChecked = (
    <BsCheckSquareFill size="25px" className="formSummary--checkedIcon" />
  );
  const bascetcase = <MdShoppingCart size="60px" />

  const nextStep = (e) => {
    

    e.preventDefault();
    emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_ORDER_TEMPLATE_ID,
        order,
        process.env.REACT_APP_EMAILJS_USER_ID)
        .then(function (response) {
        console.log("SUCCESS!", response.status, response.text);
      }, function (error) {
        console.log("FAILED...", error);
      }
    );

    const sheetOrder = { 
      Ilosc_kabin: order.cabinsValue,
      Umywalka: order.orderWashbasin,
      Dozownik: order.orderDispenser,
      Miasto_ustawienia: order.orderCity,
      Kod_pocztowy_ustawienia: order.orderPostcode,
      Ulica_ustawienia: order.orderStreet,
      Numer_ustawienia: order.orderPlaceNr,
      Numer_kontaktowy: order.orderContatNumber,
      Serwis_co:order.orderService,
      Data_ustawienia: order.orderStartingDate, 
      Data_zabrania: order.orderEndingDate,
      Nazwa_zamawiajacego: order.companyName,
      NIP: order.nip,
      PESEL: order.pesel,
      Email: order.email,
      Miasto: order.city,
      Kod_pocztowy: order.postCode,
      Ulica: order.street,
      Numer_bloku: order.placeNr,
      Numer_lokalu: order.localNr,
      Nazwa_korespondencja: order.correspName,
      Miasto_korespondencja: order.correspCity,
      Kod_pocztowy_korespondencja: order.correspPostCode,
      Ulica_korespondencja: order.correspStreet,
      Blok_korespondencja: order.correspPlaceNr,
      Lokal_korespondencja: order.correspLocalNr,
      Cena: order.orderPrice
    }
    axios.post(process.env.REACT_APP_SHHETBEST_SHHET_ID,sheetOrder)
    .then((response)=>{
      console.log(response);
    })

    setPage((currPage) => currPage + 1);
  };

  return (
    <div className="formSummary--wrapper">
      <div className="form__steps--third">
        <div></div>
        <div>
          <p>KROK 1</p>
          <p>
            Zamówienie <br />i specyfikacja kabiny
          </p>
        </div>
        <div>
          <p>KROK 2</p>
          <p>
            Uzupełnij <br />
            swoje dane
          </p>
        </div>
        <div>
          <p>KROK 3</p>
          <p>
            Podsumowanie <br />
            sprawdź informacje
          </p>
        </div>
        <div>
          <p>KROK 4</p>
          <p>
            Potwierdzenie <br />
            Twojego zamówienia
          </p>
        </div>
        <div></div>
      </div>
      <div className="formSummary--content">
        <h3>PODSUMOWANIE</h3>
        <div className="formSummary__content--wrapper">
          <div className="formSummary--orderSummary">
            <div>
              <label>ILOŚĆ KABIN:</label>
              <p> {order.cabinsValue} szt.</p>
            </div>
            <div>
              <label>ADRES USTAWIENIA:</label>
              <p>
                ul. {order.orderStreet} {order.orderPlaceNr},{" "}
                {order.orderPostcode} {order.orderCity}
              </p>
            </div>
            <div>
              <label>NUMER KONTAKTOWY:</label>
              <p>{order.orderContatNumber}</p>
            </div>
            <div>
              <label>OKRES WYNAJĘCIA:</label>
              <p>
                <b>Rozpoczęcie: </b> {orderStartDate} <br />
                <b>Zakończenie: </b> {orderEndDate}
              </p>
            </div>
            <div>
              <label>SERWISOWANIE:</label>
              <p>{order.orderService}</p>
            </div>
            <div>
              <label>DODATKOWE AKCESORIA:</label>
              <p
                style={{
                  display:
                    checked.washbasin === false && checked.dispenser === false
                      ? "block"
                      : "none",
                }}
              >
                Brak
              </p>
              <p
                style={{
                  display: checked.washbasin === true ? "block" : "none",
                }}
              >
                Umywalka
              </p>
              <p
                style={{
                  display: checked.dispenser === true ? "block" : "none",
                }}
              >
                Dozownik płynu dezynfekującego
              </p>
            </div>
          </div>
          <div className="formSummary--personalData">
            <div
              style={{ display: clientType.company === true ? "flex" : "none" }}
            >
              <label>NAZWA FIRMY:</label>
              <p> {order.companyName}</p>
            </div>
            <div
              style={{ display: clientType.private === true ? "flex" : "none" }}
            >
              <label>IMIĘ I NAZWISKO:</label>
              <p> {order.companyName}</p>
            </div>
            <div
              style={{ display: clientType.company === true ? "flex" : "none" }}
            >
              <label>NIP:</label>
              <p> {order.nip} </p>
            </div>
            <div
              style={{ display: clientType.private === true ? "flex" : "none" }}
            >
              <label>PESEL:</label>
              <p> {order.pesel} </p>
            </div>
            <div>
              <label>ADRES :</label>
              <p>
                ul. {order.street} {order.placeNr}/{order.localNr},{" "}
                {order.postCode} {order.city}
              </p>
            </div>
            <div>
              <label>EMAIL:</label>
              <p> {order.email} </p>
            </div>
            <div
              className="formSummary__correspAdress--wrapper"
              style={{ display: contactData === true ? "flex" : "none" }}
            >
              <div className="formSummary__correspAdress--header">
                {inputChecked}
                <p> Dodatkowy adres korespondencyjny:</p>
              </div>
              <div>
                <label>IMIĘ I NAZWISKO:</label>
                <p> {order.correspName} </p>
              </div>
              <div>
                <label>ADRES :</label>
                <p>
                  ul. {order.correspStreet} {order.correspPlaceNr}/
                  {order.correspLocalNr}, {order.correspPostCode}{" "}
                  {order.correspCity}
                </p>
              </div>
            </div>
            <div className="formSummary--price">
              <h3>CENA</h3>
              <div className="formSummary__price--price">
                <div>{bascetcase}</div>
                <div>
                  <p>
                    <b>{order.orderPrice}</b> zł brutto
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="next__button--wrapper">
          <p>krok: 3 z 4</p>
          <button className="order--nextButton" onClick={nextStep}>
            DALEJ
          </button>
        </div>
      </div>
    </div>
  );
};

export default FormSummary;
