import React, { useEffect } from 'react'
import '../../styles/localizations_city.css';
import { Images } from '../../assets/images/index.js'
import Header from '../Header'
import Navigation from '../Navigation'
import Copyright from '../Copyright'
import Footer from '../Footer'
import Benefits from '../Benefits'
import Localizations from '../Localizations'
import OrderRedBaner from '../Misc/Order_red_baner'

const Paslek = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    })

    return (
        <div className="city--wrapper">
            <Header />
            <Navigation />
            <div className="city--baner">
                <img src={Images.Top_baner} alt="top_baner"></img>
                <div className="city--baner__filter">
                    <h1>SERWIS</h1>
                    <h2>PASŁĘK</h2>
                </div>
            </div>
            <div className="city--mainContent">
                <div className="city--imgWrapper">
                    <img src={Images.PartyImg} alt='party_img' />
                    <div className="city--red_baner">
                        <OrderRedBaner />
                    </div>
                </div>
                <div className="city--description">
                    <h3>Komfortowe toalety przenośne – Pasłęk i okolice</h3>
                    <p>Nasza firma Serwistoalet.pl od wielu lat zajmuje się świadczeniem najwyższego standardu usług asenizacyjnych oraz oferuje na wynajem krótko i długoterminowy komfortowe toalety przenośne. Pasłęk jest jedną z wielu miejscowości w województwie warmińsko-mazurskim, w którym nasza firma cieszy się uznaniem i posiadamy rzeszę zadowolonych odbiorców (osoby prywatne, przedsiębiorcy, instytucje itd.). Zawsze stawiamy na najwyższy poziom obsługi, pełną satysfakcję naszych klientów, oferując atrakcyjne warunki cenowe i rabaty dla stałych partnerów biznesowych.</p>
                    <p>Na naszej stronie można znaleźć specjalny formularz online do składania zamówień, który pomaga w identyfikacji i wyskalowaniu potrzeb oraz przygotowaniu personalizowanej oferty. Zamówienia przyjmujemy w cyklu całodobowym (24/7) z terminem realizacji maksymalnie do 12 godzin. Kabiny WC w Pasłęku dowozimy pod wskazany adres, włącznie z rozładowaniem, montażem i zapewnieniem kompleksowego serwisu w okresie trwania wynajmu na mobilne toalety. Serwis obejmuje swym zakresem czyszczenie, zdezynfekowanie oraz uzupełnienie środków eksploatacyjnych i materiałów higienicznych (papier toaletowy, mydło). Usługi Serwistoalet.pl dostępne są na terenie całego Pasłęka, obejmując swym zasięgiem takie części miasta, jak Popowe Włóki i Zdroje.</p>
                    <h3>Gdzie przydadzą się toalety przenośne w Pasłęku?</h3>
                    <div className="city__mainContent--list">
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="top_baner"/>
                            <p>PLACE BUDOWY,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="top_baner"/>
                            <p>IMPREZY MASOWE (NP. FESTYNY, KONCERTY, WYDARZENIA SPORTOWE, OBCHODY ROCZNICOWE),</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="top_baner"/>
                            <p>MIEJSCA ZBIOROWEGO WYPOCZYNKU (BIWAKI, KEMPINGI, OBOZY, KOLONIE, POLA NAMIOTOWE),</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="top_baner"/>
                            <p>MIEJSCA W POBLIŻU ATRAKCJI TURYSTYCZNYCH,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="top_baner"/>
                            <p>MIEJSCA ORGANIZACJI PRYWATNYCH EVENTÓW W PLENERZE (IMPREZY FIRMOWE, OKOLICZNOŚCIOWE),</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="top_baner"/>
                            <p>PARKI, PUBLICZNE PLACE ZABAW, PLENEROWE STREFY WORKOUT,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="top_baner"/>
                            <p>LOKALE I OGRÓDKI GASTRONOMICZNE,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="top_baner"/>
                            <p>OGRÓDKI DZIAŁKOWE,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="top_baner"/>
                            <p>PARKINGI.</p>
                        </div>
                    </div>
                    <h3>Serwisowane toalety (Pasłęk) – oferta dopasowana do potrzeb</h3>
                    <p>Wolnostojące, uniwersalne i serwisowane toalety są bardzo solidne i stabilne, doskonale nadają się na budowę, na imprezę, do parku, jak na biwak czy na parking. Nasze przenośne toalety cechuje mocna konstrukcja, przez co kabiny odporne są na uszkodzenia mechaniczne, bez kłopotu można je także użytkować przez okrągły rok (materiały są odporne na wilgoć i ekstremalne wahania temperatury). </p>
                    <p>Wszystkie mobilne toalety wyposażone są w system podwójnej wentylacji, a w zbiorniku bezodpływowym umieszczane są specjalistyczne środki eliminujące fetor. Oprócz zamykanych od środka drzwi z oznaczeniem wolne/zajęte, sedesu, uchwytu na papier czy antypoślizgowej podłogi, nasze ubikacje przenośne mogą być doposażone opcjonalnie w umywalkę ze zbiornikiem wody (ok. 40 l) oraz płynne mydło w dozowniku.</p>
                    <h3>Zapraszamy do współpracy firmy, instytucje, urzędy i osoby fizyczne z całego Pasłęka!</h3>
                </div>
            </div>
            <div className="city__benefits--wrapper">
                <Benefits />
            </div>
            <div className="city__last_red_baner--wrapper">
                <div>
                    <OrderRedBaner />
                </div>
            </div>
            <Footer />
            <Localizations />
            <Copyright />
        </div>

    )
}

export default Paslek