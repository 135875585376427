import React, { useState, useEffect } from 'react'
import "../styles/asenizationOrder.css"
import "../styles/order.css"
import Header from './Header'
import Navigation from './Navigation'
import Copyright from './Copyright'
import Footer from './Footer'
import FooterOrder from './Footer_order'
import Localizations from './Localizations'
import { Images } from '../assets/images/index.js'
import { HiOutlineBell } from 'react-icons/hi'
import SanitationService from './sanitationForm/sanitationService'
import SanitationPersonalInfo from './sanitationForm/SanitationPersonalInfo'
import SanitationSummary from './sanitationForm/SanitationSummary'
import SanitationEnding from './sanitationForm/SanitationEnding'
import { format } from 'date-fns'


const SanitationOrder = () => {

    let dateNow = new Date();
    const [sanitationDate, setSanitationDate] = useState(new Date(dateNow))
    const [sanitationOrderDate, setSanitationOrderDate] = useState()

    const [clientType, setClientType] = useState({
        company: true,
        private: false,
        
    })

    const [sanitationOrder, setSanitationOrder] = useState({
        sanitationType: 'Nieczystości bytowe',
        sanitationValue: '',
        orderDate: '',
        orderCity: '',
        orderPostcode: '',
        orderStreet: '',
        orderPlaceNr: '',
        orderContactNumber: '',
        orderDistance: '',
        companyName: '',
        nip:'',
        city:'',
        street:'',
        postCode: '',
        placeNr:'',
        localNr:'',
        email:'',
        pesel: '',
        correspName: '',
        correspCity: '',
        correspPostCode:'',
        correspStreet: '',
        correspPlaceNr: '',
        correspLocalNr: '',
        distance: ''
    })

    const [contactData, setContactData] = useState(false)
    
    useEffect(() => {
        window.scrollTo(0, 0);
    },[])

    useEffect(()=> {
        setSanitationOrderDate(format(new Date(sanitationDate), 'yyyy-MM-dd'))
    },[sanitationDate])

    const [sanPage, setSanPage] = useState(0)

    const SanPageDisplay = () => {
        if (sanPage === 0) {
            return <SanitationService dateNow={dateNow} sanPage={sanPage} setSanPage={setSanPage} sanitationOrder={sanitationOrder} setSanitationOrder={setSanitationOrder} sanitationDate={sanitationDate} setSanitationDate={setSanitationDate} sanitationOrderDate={sanitationOrderDate} setSanitationOrderDate={setSanitationOrderDate}/>
        }

        if (sanPage === 1) {
            return <SanitationPersonalInfo clientType={clientType} setClientType={setClientType} contactData={contactData} setContactData={setContactData} sanPage={sanPage} setSanPage={setSanPage} sanitationOrder={sanitationOrder} setSanitationOrder={setSanitationOrder}/>
        }

        if (sanPage === 2) {
            return <SanitationSummary clientType={clientType} setClientType={setClientType} sanPage={sanPage} setSanPage={setSanPage} sanitationOrder={sanitationOrder} setSanitationOrder={setSanitationOrder}/>
        }

        if (sanPage === 3) {
            return <SanitationEnding/>
        }
    }


    return (

        <div className="asenizationOrder--wrapper">
            <Header />
            <Navigation />
            <div className="order--baner">
                <img src={Images.Top_baner} alt="top_baner"></img>
                <div className="order--baner__filter">
                    <h1>ZAMÓWIENIE</h1>
                </div>
            </div>
            <div className="asenizationOrder--mainContent">
                <div className="order__mainContent--header" style={{ display: sanPage === 0 ? "flex" : "none" }}>
                    <h3>ZAMÓW</h3>
                    <div className="mainContent__header--info">
                        <div className="mainContent__header--infoWrapper">
                            <div>
                                <p>BRAK <b>ZBĘDNYCH FORMALNOŚCI</b></p>
                            </div>
                            <div>
                                <p>DOBRA CENA DLA <b>STAŁYCH KLIENTÓW</b></p>
                            </div>
                            <div>
                                <p>FACHOWA OBSŁUGA</p>
                            </div>
                            <div>
                                <p>WYWÓZ W CIĄGU <b>12H OD ZAMÓWIENIA*</b> </p>
                            </div>
                        </div>
                    </div>
                    <div className="mainContent__header--lastInfo">
                        <div className="bell__container">
                            <HiOutlineBell className="bell__ico" />
                        </div>
                        <div className="mainContent__header--description">
                            <p>Przez ten formularz możesz zamówić wywóz nieczystości, jeśli <b>potrzebujesz innych rozwiązań</b> lub <b>masz pytania</b> dotyczące zamawiania wywozu nieczystości to zapraszamy do kontaktu: <b>733 435 435, biuro@serwistoalet.pl</b> <br/><br/>* za dodatkową opłatą</p>
                        </div>
                    </div>
                </div>
                <div className='asenizationOrder--form'>
                    {SanPageDisplay()}
                </div>

            </div>
            {sanPage === 0 ? <FooterOrder /> : <Footer />}
            <Localizations />
            <Copyright />
        </div>
    )
}

export default SanitationOrder;