import React, { useEffect } from 'react'
import '../../styles/localizations_city.css';
import { Images } from '../../assets/images/index.js'
import Header from '../Header'
import Navigation from '../Navigation'
import Copyright from '../Copyright'
import Footer from '../Footer'
import Benefits from '../Benefits'
import Localizations from '../Localizations'
import OrderRedBaner from '../Misc/Order_red_baner'

const Ostroda = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    })

    return (
        <div className="city--wrapper">
            <Header />
            <Navigation />
            <div className="city--baner">
                <img src={Images.Top_baner} alt="top_baner"></img>
                <div className="city--baner__filter">
                    <h1>SERWIS</h1>
                    <h2>OSTRÓDA</h2>
                </div>
            </div>
            <div className="city--mainContent">
                <div className="city--imgWrapper">
                    <img src={Images.PartyImg} alt='party_img' />
                    <div className="city--red_baner">
                        <OrderRedBaner />
                    </div>
                </div>
                <div className="city--description">
                    <h3>Tanie toalety przenośne – Ostróda i okolice</h3>
                    <p>Nasza firma Serwistoalet.pl zajmuje się świadczeniem profesjonalnych usług asenizacyjnych oraz oferuje na wynajem krótko i długoterminowy solidne i tanie toalety przenośne. Ostróda jest jedną z wielu miejscowości w województwie warmińsko-mazurskim, w którym mamy szerokie grono odbiorców (osoby fizyczne, przedsiębiorcy, urzędy itd.). Stawiamy na wysoki poziom obsługi, satysfakcję naszych klientów i proponujemy atrakcyjne warunki cenowe.</p>
                    <p>Uruchomiliśmy specjalny formularz internetowy do składania zamówień, który pomaga w zidentyfikowaniu i skalowaniu potrzeb oraz wygenerowaniu spersonalizowanej oferty. Zamówienia przyjmowane są w trybie całodobowym (24/7) z terminem realizacji do 12 h. Kabiny WC w Ostródzie dostarczamy pod ustalony adres, włącznie z rozładunkiem i zapewnieniem okresowego serwisu w czasie trwania wynajmu na mobilne toalety. Serwis obejmuje dokładne mycie, dezynfekcję oraz uzupełnienie płynów eksploatacyjnych i środków higieny (papier toaletowy, mydło, woda). Działamy na terenie całej Ostródy, obejmując swym zasięgiem różne części miasta, jak np.: Ostrów, Fiugajny, Zajezierze i Cierzpięty.</p>
                    <h3>Gdzie przydadzą się toalety przenośne w Ostródzie?</h3>
                    <div className="city__mainContent--list">
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="top_baner"/>
                            <p>PLACE BUDOWY,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="top_baner"/>
                            <p>IMPREZY MASOWE (NP. FESTYNY, KONCERTY, WYDARZENIA SPORTOWE, OBCHODY ROCZNICOWE),</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="top_baner"/>
                            <p>MIEJSCA ZBIOROWEGO WYPOCZYNKU (BIWAKI, KEMPINGI, OBOZY, KOLONIE, POLA NAMIOTOWE),</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="top_baner"/>
                            <p>MIEJSCA W POBLIŻU ATRAKCJI TURYSTYCZNYCH,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="top_baner"/>
                            <p>MIEJSCA ORGANIZACJI PRYWATNYCH EVENTÓW W PLENERZE (IMPREZY FIRMOWE, OKOLICZNOŚCIOWE),</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="top_baner"/>
                            <p>PARKI, PUBLICZNE PLACE ZABAW, PLENEROWE STREFY WORKOUT,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="top_baner"/>
                            <p>LOKALE I OGRÓDKI GASTRONOMICZNE,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="top_baner"/>
                            <p>OGRÓDKI DZIAŁKOWE,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="top_baner"/>
                            <p>PARKINGI.</p>
                        </div>
                    </div>
                    <h3>Mobilne ubikacje (Ostróda) – oferta dopasowana do potrzeb</h3>
                    <p>Uniwersalne i mobilne ubikacje są solidne i stabilne, świetnie sprawdzają się zarówno na budowie, na imprezie, w parku, jak i na kempingu. Nasze przenośne toalety charakteryzują się mocną konstrukcją, przez co są odporne na uszkodzenia mechaniczne, bez problemu można je też użytkować całorocznie (materiały są odporne na wilgoć i duże wahania temperatury).</p>
                    <p>Wszystkie mobilne toalety wyposażone są w podwójną wentylację, a w zbiorniku bezodpływowym umieszczone zostają specjalne środki eliminujące nieprzyjemny zapach. Oprócz zamykanych od środka drzwi, sedesu, uchwytu na papier czy antypoślizgowej podłogi, kabiny WC mogą być doposażone dodatkowo w umywalkę z zasobnikiem wody i mydło w płynie.</p>
                    <h3>Zapraszamy do współpracy firmy, instytucje, urzędy i osoby fizyczne z całej Ostródzie!</h3>
                </div>
            </div>
            <div className="city__benefits--wrapper">
                <Benefits />
            </div>
            <div className="city__last_red_baner--wrapper">
                <div>
                    <OrderRedBaner />
                </div>
            </div>
            <Footer />
            <Localizations />
            <Copyright />
        </div>

    )
}

export default Ostroda