import React, { useEffect } from 'react'
import '../../styles/localizations_city.css';
import { Images } from '../../assets/images/index.js'
import Header from '../Header'
import Navigation from '../Navigation'
import Copyright from '../Copyright'
import Footer from '../Footer'
import Benefits from '../Benefits'
import Localizations from '../Localizations'
import OrderRedBaner from '../Misc/Order_red_baner'

const Gdansk = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    })

    return (
        <div className="city--wrapper">
            <Header />
            <Navigation />
            <div className="city--baner">
                <img src={Images.Top_baner} alt="top_baner"></img>
                <div className="city--baner__filter">
                    <h1>SERWIS</h1>alt="LiIcon"
                    <h2>GDAŃSK</h2>
                </div>
            </div>
            <div className="city--mainContent">
                <div className="city--imgWrapper">
                    <img src={Images.PartyImg} alt='party_img' />
                    <div className="city--red_baner">
                        <OrderRedBaner />
                    </div>
                </div>
                <div className="city--description">
                    <h3>Funkcjonalne toalety przenośne – Gdańsk i okolice</h3>
                    <p>Przedsiębiorstwo Serwistoalet.pl wspiera aktywnie podmioty z województwa pomorskiego w przygotowaniu plenerowego zaplecza sanitarnego, dostarczając dla nich tanie toalety przenośne. Gdańsk jest jednym z wielu miast północnego regionu Polski, w którym nasza firma oferuje swoje usługi. Proponujemy krótko- oraz długookresowy wynajem toalet przenośnych na terenie Gdańska – zarówno dla odbiorców indywidualnych, firm i urzędów, zapewniając przy tym najwyższy standard usług oraz bardzo atrakcyjne ceny.</p>
                    <p>Na stronie serwistoalet.pl można znaleźć intuicyjny formularz online, umożliwiający złożenie zamówienia, które zrealizujemy w ciągu 12 godzin. Nasze funkcjonalne toalety przenośne to idealne rozwiązanie – wszędzie tam, gdzie brakuje istniejącego zaplecza sanitarnego oraz nie ma dostępu do instalacji wodno-kanalizacyjnej. Dostarczamy mobilne kabiny WC pod wskazany adres i montujemy w ustalonym miejscu, bez względu na warunki terenowe. Firma Serwistoalet.pl świadczy swoje usługi na terenie całego Gdańska, obsługując m.in. takie dzielnice, jak: Brzeźno, Chełm, Jasień, Oliwa, Orunia Górna-Gdańsk Południe, Piecki- Migowo, Przymorze Wielkie czy Ujeścisko-Łostowice.</p>
                    <h3>Gdzie przydadzą się toalety przenośne w Gdańsku?</h3>
                    <div className="city__mainContent--list">
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>PLACE BUDOWY,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>IMPREZY MASOWE (NP. FESTYNY, KONCERTY, WYDARZENIA SPORTOWE, OBCHODY ROCZNICOWE),</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>MIEJSCA ZBIOROWEGO WYPOCZYNKU (BIWAKI, KEMPINGI, OBOZY, KOLONIE, POLA NAMIOTOWE),</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>MIEJSCA W POBLIŻU ATRAKCJI TURYSTYCZNYCH,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>MIEJSCA ORGANIZACJI PRYWATNYCH EVENTÓW W PLENERZE (IMPREZY FIRMOWE, OKOLICZNOŚCIOWE),</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>PARKI, PUBLICZNE PLACE ZABAW, PLENEROWE STREFY WORKOUT,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>LOKALE I OGRÓDKI GASTRONOMICZNE,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>OGRÓDKI DZIAŁKOWE,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>PARKINGI.</p>
                        </div>
                    </div>
                    <h3>Mobilne kabiny WC (Gdańsk) – oferta dopasowana do potrzeb</h3>
                    <p>Toalety mobilne oferowane przez Serwistoalet.pl cechują się solidnością, nowoczesnym designem i można je instalować na różnych typach podłoża. Do produkcji przenośnych kabin WC użyto wysokiej jakości materiałów, które są wytrzymałe na mechaniczne uszkodzenia i ekstremalne warunki atmosferyczne. Toalety wyposażone są w dwa systemy wentylacji, podłogę antypoślizgową, uchwyt na papier toaletowy, pisuar, deskę sedesową oraz pojemny zbiornik bezodpływowy. Opcjonalnie, kabiny WC mogą zostać doposażone m.in. w umywalkę ze zbiornikiem wody oraz dozownik z mydłem.</p>
                    <h3>Zapraszamy do współpracy firmy, instytucje, urzędy i osoby fizyczne z całego Gdańska!</h3>
                </div>
            </div>
            <div className="city__benefits--wrapper">
                <Benefits />
            </div>
            <div className="city__last_red_baner--wrapper">
                <div>
                    <OrderRedBaner />
                </div>
            </div>
            <Footer />
            <Localizations />
            <Copyright />
        </div>

    )
}

export default Gdansk