import React, { useEffect } from 'react'
import '../../styles/orderStyles/formPersonalInfo.css'
import { BsCheckSquareFill, BsCheckSquare } from 'react-icons/bs'
import { MdShoppingCart } from 'react-icons/md'
import InputMask from "react-input-mask";


const FormPersonalInfo = ({ page, setPage, order, setOrder, orderStartDate, orderEndDate, checked, clientType, setClientType, contactData, setContactData }) => {

    useEffect(() => {
        window.scrollTo(0, 0);
    },[])

    const chandleClientType = (e) => {
        if (e.target === document.querySelector("#companyInput") && clientType.company !== true) {
            setClientType({ ...clientType, company: !clientType.company, private: !clientType.private })
        }

        if (e.target === document.querySelector("#privateInput") && clientType.private !== true) {
            setClientType({ ...clientType, company: !clientType.company, private: !clientType.private })
        }
    }

    const inputChecked = <BsCheckSquareFill size='40px' />
    const inputNotChecked = <BsCheckSquare size='40px' className='formPersonalInfo__notCheckedIcon' />
    const bascetcase = <MdShoppingCart size='60px' />

    const changeSubmitForm = (e) => {
        e.preventDefault()
        setPage((currPage) => currPage + 1)
    }

    return (

        <form className="formPersonalInfo--wrapper" onSubmit={changeSubmitForm}>
            <div className="form__steps--secound">
                <div></div>
                <div>
                    <p>KROK 1</p>
                    <p>Zamówienie <br />i specyfikacja kabiny</p>
                </div>
                <div>
                    <p>KROK 2</p>
                    <p>Uzupełnij <br />swoje dane</p></div>
                <div>
                    <p>KROK 3</p>
                    <p>Podsumowanie <br />sprawdź informacje</p></div>
                <div>
                    <p>KROK 4</p>
                    <p>Potwierdzenie <br />Twojego zamówienia</p></div>
                <div></div>
            </div>
            <div className="formPersonalInfo--form">
                <div className="formPersonalInfo__form--inputsWrapper">
                    <h3>DANE</h3>
                    <div className="formPersonalInfo__inputsHolder">
                        <div>
                            <input
                                type="radio"
                                id="companyInput"
                                name="setClientProfile"
                                value={clientType.company}
                                onChange={chandleClientType}
                            />
                            {clientType.company ? inputChecked : inputNotChecked}
                            <label>Działalność gospodarcza</label>
                        </div>
                        <div>
                            <input
                                type="radio"
                                id="privateInput"
                                name="setClientProfile"
                                value={clientType.private}
                                onChange={chandleClientType}
                            />
                            {clientType.private ? inputChecked : inputNotChecked}
                            <label>Osoba prywatna</label>
                        </div>
                    </div>
                    <div className="formPersonalInfo__form--companyForm"
                        style={{ display: clientType.company === true ? "flex" : "none" }}
                    >
                        <input
                            type="text"
                            name="company_name"
                            placeholder="Nazwa firmy"
                            value={order.companyName}
                            onChange={(e) => {
                                setOrder({ ...order, companyName: e.target.value })
                            }}
                            required={clientType.company}
                        />
                        <input
                            type="text"
                            name="nip"
                            placeholder="NIP"
                            value={order.nip}
                            onChange={(e) => {
                                setOrder({ ...order, nip: e.target.value })
                            }}
                            minLength='10'
                            maxLength='10'
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            required={clientType.company}
                        />
                        <input
                            type="text"
                            name="city"
                            placeholder="Miasto"
                            value={order.city}
                            onChange={(e) => {
                                setOrder({ ...order, city: e.target.value })
                            }}
                            required
                        />
                        <InputMask
                            type="text"
                            placeholder="Kod pocztowy"
                            max={6}
                            value={order.postCode}
                            onChange={(e) => {
                                setOrder({ ...order, postCode: e.target.value })
                            }}
                            mask="99-999"
                            required
                        />
                        <input
                            type="text"
                            name="street"
                            placeholder="Ulica"
                            value={order.street}
                            onChange={(e) => {
                                setOrder({ ...order, street: e.target.value })
                            }}
                            required
                        />
                        <input
                            type="text"
                            name="placeNr"
                            placeholder="Nr budynku"
                            value={order.placeNr}
                            onChange={(e) => {
                                setOrder({ ...order, placeNr: e.target.value })
                            }}
                            required
                        />
                        <input
                            type="text"
                            name="localNr"
                            placeholder="Nr lokalu"
                            value={order.localNr}
                            onChange={(e) => {
                                setOrder({ ...order, localNr: e.target.value })
                            }}
                        />
                        <input
                            type="email"
                            name="email"
                            placeholder="Adres e-mail"
                            value={order.email}
                            onChange={(e) => {
                                setOrder({ ...order, email: e.target.value })
                            }}
                            required
                        />
                    </div>
                    <div className="formPersonalInfo__form--companyForm"
                        style={{ display: clientType.private === true ? "flex" : "none" }}
                    >
                        <input
                            type="text"
                            name="nameAndSurname"
                            placeholder="Imię i Nazwisko"
                            value={order.companyName}
                            onChange={(e) => {
                                setOrder({ ...order, companyName: e.target.value })
                            }}
                            required={clientType.private}
                        />
                        <input
                            type="text"
                            placeholder="PESEL"
                            value={order.pesel}
                            onChange={(e) => {
                                setOrder({ ...order, pesel: e.target.value })
                            }}
                            minLength='11'
                            maxLength='11'
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            required={clientType.private}
                        />
                        <input
                            type="text"
                            name="city"
                            placeholder="Miasto"
                            value={order.city}
                            onChange={(e) => {
                                setOrder({ ...order, city: e.target.value })
                            }}
                            required
                        />
                        <InputMask
                            type="text"
                            placeholder="Kod pocztowy"
                            max={6}
                            value={order.postCode}
                            onChange={(e) => {
                                setOrder({ ...order, postCode: e.target.value })
                            }}
                            mask="99-999"
                            required
                        />
                        <input
                            type="text"
                            name="street"
                            placeholder="Ulica"
                            value={order.street}
                            onChange={(e) => {
                                setOrder({ ...order, street: e.target.value })
                            }}
                            required
                        />
                        <input
                            type="text"
                            name="placeNr"
                            placeholder="Nr budynku"
                            value={order.placeNr}
                            onChange={(e) => {
                                setOrder({ ...order, placeNr: e.target.value })
                            }}
                            required
                        />
                        <input
                            type="text"
                            name="localNr"
                            placeholder="Nr lokalu"
                            value={order.localNr}
                            onChange={(e) => {
                                setOrder({ ...order, localNr: e.target.value })
                            }}
                        />
                        <input
                            type="email"
                            name="email"
                            placeholder="Adres e-mail"
                            value={order.email}
                            onChange={(e) => {
                                setOrder({ ...order, email: e.target.value })
                            }}
                            required
                        />
                    </div>
                    <div className="formPersonalInfo__form--contactData">
                        <input
                            type="checkbox"
                            name="contactData"
                            checked={contactData}
                            onChange={e => setContactData(!contactData)}
                        />
                        {contactData ? inputChecked : inputNotChecked}
                        <label>Dodatkowy adres korespondencyjny</label>
                    </div>
                    <div className="formPersonalInfo__contactData"
                        style={{ display: contactData === true ? "flex" : "none" }}
                    >
                        <input
                            type="text"
                            name="correspName"
                            placeholder="Imię i Nazwisko"
                            value={order.correspName}
                            onChange={(e) => {
                                setOrder({ ...order, correspName: e.target.value })
                            }}
                        />
                        <input
                            type="text"
                            name="correspCity"
                            placeholder="Miasto"
                            value={order.correspCity}
                            onChange={(e) => {
                                setOrder({ ...order, correspCity: e.target.value })
                            }}
                        />
                        <InputMask
                            type="text"
                            placeholder="Kod pocztowy"
                            max={6}
                            value={order.correspPostCode}
                            onChange={(e) => {
                                setOrder({ ...order, correspPostCode: e.target.value })
                            }}
                            mask="99-999"
                        />
                        <input
                            type="text"
                            name="correspStreet"
                            placeholder="Ulica"
                            value={order.correspStreet}
                            onChange={(e) => {
                                setOrder({ ...order, correspStreet: e.target.value })
                            }}
                        />
                        <input
                            type="text"
                            name="correspPlaceNr"
                            placeholder="Nr budynku"
                            value={order.correspPlaceNr}
                            onChange={(e) => {
                                setOrder({ ...order, correspPlaceNr: e.target.value })
                            }}
                        />
                        <input
                            type="text"
                            name="correspLocalNr"
                            placeholder="Nr lokalu"
                            value={order.correspLocalNr}
                            onChange={(e) => {
                                setOrder({ ...order, correspLocalNr: e.target.value })
                            }}
                        />
                    </div>

                </div>
                <div className="formPersonalInfo--orderSummary">
                    <h3>SZCZEGÓŁY</h3>
                    <div className="formPersonalInfo--summary">
                        <div>
                            <label>ILOŚĆ KABIN:</label>
                            <p> {order.cabinsValue} szt.</p>
                        </div>
                        <div>
                            <label>ADRES USTAWIENIA:</label>
                            <p>ul. {order.orderStreet} {order.orderPlaceNr}, {order.orderPostcode} {order.orderCity}</p>
                        </div>
                        <div>
                            <label>NUMER KONTAKTOWY:</label>
                            <p>{order.orderContatNumber}</p>
                        </div>
                        <div>
                            <label>OKRES WYNAJĘCIA:</label>
                            <p><b>Rozpoczęcie: </b> {orderStartDate} <br /><b>Zakończenie: </b> {orderEndDate}</p>
                        </div>
                        <div>
                            <label>SERWISOWANIE:</label>
                            <p>{order.orderService}</p>
                        </div>
                        <div>
                            <label>DODATKOWE AKCESORIA:</label>
                            <p style={{ display: checked.washbasin === false && checked.dispenser === false ? "block" : "none" }}>Brak</p>
                            <p style={{ display: checked.washbasin === true ? "block" : "none" }}>Umywalka</p>
                            <p style={{ display: checked.dispenser === true ? "block" : "none" }}>Dozownik płynu dezynfekującego</p>
                        </div>
                        <div className="formPersonalInfo__summary--price">
                            <h3>KOSZT</h3>
                            <div className="formPersonalInfo__price--price">
                                <div>{bascetcase}</div>
                                <div>
                                    <p><b>{order.orderPrice}</b> zł brutto</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='next__button--wrapper'>
                <p>krok: 2 z 4</p>
                <button type='submit' className="order--nextButton">DALEJ</button>
            </div>

        </form>
    )

}

export default FormPersonalInfo