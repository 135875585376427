import React, { useState, useEffect } from 'react'
import '../styles/contact.css'
import { Images } from '../assets/images/index.js'
import Header from './Header'
import Navigation from './Navigation'
import Copyright from './Copyright'
import GoogleMap from './GoogleMap'
import { MdMail, MdSmartphone } from 'react-icons/md'
import { FaMapMarkerAlt } from 'react-icons/fa'
import emailjs from '@emailjs/browser';
import { motion } from "framer-motion";
import Localizations from './Localizations'

const Contact = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    })

    let [contact, setContact] = useState({
        name: '',
        email: '',
        message: ''
    })

    let[hide, setHide] = useState(false)

    function sendMessage(e) {

        const contactForm = document.querySelector('#contactForm');

        e.preventDefault();

        emailjs.sendForm(
            process.env.REACT_APP_EMAILJS_SERVICE_ID,
            process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
            contactForm,
            process.env.REACT_APP_EMAILJS_USER_ID)
            .then(function (response) {
                console.log('SUCCESS!', response.status, response.text);
            }, function (error) {
                console.log('FAILED...', error);
            });

        setContact({ ...contact, name: '', email: '', message: '' })
        setHide(!hide)
    }

    const animateFrom = { opacity: 0 }
    const animateTo = { opacity: 1 }

    return (
        <div className="contact--wrapper">
            <Header />
            <Navigation />
            <div className="contact--baner">
                <img src={Images.Top_baner} alt="top_baner"></img>
                <div className="contact--baner__filter">
                    <h1>FORMULARZ</h1>
                </div>
            </div>
            <div className="contact--mainContent">
                <h3>FORMULARZ</h3>
                <div className="contact__form--wrapper">
                    <form id="contactForm" onSubmit={sendMessage}>
                        <input
                            id='nameInput'
                            type="text"
                            name='name'
                            placeholder='Imię i Nazwisko'
                            value={contact.name}
                            onChange={(e) => {
                                setContact({ ...contact, name: e.target.value })
                            }}
                            required
                        />
                        <input
                            id='emailInput'
                            type='email'
                            name='email'
                            placeholder='Adres e-mail'
                            value={contact.email}
                            onChange={(e) => {
                                setContact({ ...contact, email: e.target.value })
                            }}
                            required
                        />
                        <textarea
                            id='messageInput'
                            name='message'
                            placeholder='Twoja wiadomość'
                            maxLength='500'
                            wrap='soft'
                            value={contact.message}
                            onChange={(e) => {
                                setContact({ ...contact, message: e.target.value })
                            }}
                            required
                        />
                        <button
                            type='submit'
                            className="contact--submitBtn"
                        >
                            <MdMail className="contact__button--icon" />
                            <p>WYŚLIJ</p>
                        </button>
                        {hide ? 
                        <motion.div
                            initial={animateFrom}
                            animate={animateTo}
                            transition={{delay: 0.5, duration:1}}
                            className="contact--sended"
                            id="sendInfo">
                            <p>Dziękujemy za wiadomość. <br /> Odpowiemy najszybciej jak to możliwe.</p>
                        </motion.div> : null}
                    </form>
                </div>
            </div>
            <div className="contact--footer">
                <div className="contact__footer--imgWrapper">
                    <img src={Images.Logo} alt="logo" />
                </div>
                <div className="contact__media">
                    <MdMail className="contact__media--icon" />
                    <p>biuro@serwistoalet.pl</p>
                    <p>E-mail</p>
                </div>
                <div className="contact__media">
                    <MdSmartphone className="contact__media--icon" />
                    <p>+48 733 435 435</p>
                    <p>Telefon</p>
                </div>
                <div className="contact__media">
                    <FaMapMarkerAlt className="contact__media--icon" />
                    <p>ul. Krakusa 8/U1<br/> 82-300 Elbląg</p>
                    <p>Siedziba</p>
                </div>
                <div className="contact__media">
                    <FaMapMarkerAlt className="contact__media--icon" />
                    <p>ul. Jaśminowa 6<br/> 82-300 Elbląg</p>
                    <p>Biuro Obsługi Klienta</p>
                </div>
            </div>
            <div className="contact__map--wrapper">
                <GoogleMap />
            </div>
            <Localizations />
            <Copyright />
        </div>
    )
}

export default Contact