import React, { useEffect } from 'react'
import '../styles/offert.css'
import { Images } from '../assets/images/index.js'
import Header from './Header'
import Navigation from './Navigation'
import Copyright from './Copyright'
import Footer from './Footer'
import Benefits from './Benefits'
import OrderSanitationBaner from './Misc/Order_sanitation_baner'
import Localizations from './Localizations'

const OffertSanitation = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    })

    return (
        <div className="offert-wrapper">
            <Header />
            <Navigation />
            <div className="offert--baner">
                <img src={Images.Top_baner} alt="top_baner"></img>
                <div className="offert--baner__filter">
                    <h1 className="offert--sanitationTitle">USŁUGI</h1>
                </div>
            </div>
            <div className="offert--mainContent">
                <div className="offert--imgWrapper">
                    <img src={Images.SanitationImg2} alt='baner_sanitation' />
                    <div className="offert--red_baner">
                        <OrderSanitationBaner />
                    </div>
                </div>
                <div className="offert--description">
                    <h3>Profesjonalne usługi asenizacyjne – Elbląg i okolice – szybka i skuteczna interwencja</h3>
                    <p>Firma SERWISTOALET.PL świadczy kompleksowe usługi asenizacyjne, takie jak wywóz i utylizacja nieczystości płynnych – bytowych oraz przemysłowych. Dysponujemy specjalistycznymi pojazdami, sprzętem oraz wykwalifikowanymi pracownikami do oczyszczania i wywozu ścieków z takich instalacji, jak np.:</p>
                    <div className="offert__mainContent--list">
                        <div className="offert__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>KANALIZACJE SANITARNE I DESZCZOWE, </p>
                        </div>
                        <div className="offert__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>SZAMBA, </p>
                        </div>
                        <div className="offert__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>ZBIORNIKI BEZODPŁYWOWE,</p>
                        </div>
                        <div className="offert__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>PRZYDOMOWE OCZYSZCZALNIE EKOLOGICZNE. </p>
                        </div>
                    </div>
                    <p>Usługi asenizacyjne wykonujemy zarówno na zlecenie prywatnych osób, jak i klientów instytucjonalnych – głównie w promieniu 50 km od Elbląga. Nasze pogotowie asenizacyjne realizuje również usługi interwencyjne, związane z likwidacją skutków awarii sieci/instalacji oraz lokalnych kataklizmów, jak np.:</p>
                    <div className="offert__mainContent--list">
                        <div className="offert__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>WYPOMPOWANIE WODY/ŚCIEKÓW Z ZALANYCH PIWNIC CZY PODZIEMNYCH GARAŻY/PARKINGÓW,</p>
                        </div>
                        <div className="offert__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>ODPOMPOWANIE  WODY Z WYKOPÓW BUDOWLANYCH,</p>
                        </div>
                        <div className="offert__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>USUNIĘCIE WODY Z PODTOPIONYCH TERENÓW (POWODZIE PO INTENSYWNYCH OPADACH, PRZERWANYCH WAŁACH RZECZNYCH CZY WYSTĄPIENIU Z BRZEGÓW ZBIORNIKÓW WODNYCH).</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="offert__benefits--wrapper">
                <Benefits />
            </div>
            <div className="offert__last_red_baner--wrapper">
                <div>
                    <OrderSanitationBaner />
                </div>
            </div>
            <Footer />
            <Localizations />
            <Copyright />
        </div>
    )
}

export default OffertSanitation