import React, { useEffect } from 'react'
import '../../styles/localizations_city.css';
import { Images } from '../../assets/images/index.js'
import Header from '../Header'
import Navigation from '../Navigation'
import Copyright from '../Copyright'
import Footer from '../Footer'
import Benefits from '../Benefits'
import Localizations from '../Localizations'
import OrderRedBaner from '../Misc/Order_red_baner'

const Braniewo = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    })

    return (
        <div className="city--wrapper">
            <Header />
            <Navigation />
            <div className="city--baner">
                <img src={Images.Top_baner} alt="top_baner"></img>
                <div className="city--baner__filter">
                    <h1>SERWIS</h1>
                    <h2>BRANIEWO</h2>
                </div>
            </div>
            <div className="city--mainContent">
                <div className="city--imgWrapper">
                    <img src={Images.PartyImg} alt='party_img' />
                    <div className="city--red_baner">
                        <OrderRedBaner />
                    </div>
                </div>
                <div className="city--description">
                    <h3>Profesjonalne toalety przenośne – Braniewo i okolice</h3>
                    <p>Nasze przedsiębiorstwo Serwistoalet.pl już od kilku lat świadczy kompleksowe usługi, ukierunkowane przede wszystkim na wynajem toalet przenośnych i obsługę asenizacyjną na terenie Warmii i Mazur (krótko- i długoterminowy wynajem przenośnych kabinWC). Z mobilnego zaplecza sanitarnego, na które składają się profesjonalne toalety przenośne – w Braniewie – korzystają zarówno osoby fizyczne, przedsiębiorcy (np. firmy budowlane, eventowe, zarządcy publicznych stref rekreacyjno-sportowych), samorządy, jak i różne instytucje. Nasi klienci zawsze mogą liczyć na najlepszą jakość usług oraz bardzo konkurencyjne ceny, szczególnie jeśli chodzi o wynajem długookresowy toalet przenośnych.</p>
                    <p>Przyjmujemy zamówienia telefonicznie, mailowo oraz za pośrednictwem formularza online, dzięki któremu możemy łatwo wyskalować potrzeby i przedstawić zoptymalizowaną ofertę. Zamówienia realizowane są najpóźniej do 12 godzin od momentu, gdy otrzymamy zgłoszenie konkretnego zapotrzebowania. Dowozimy toalety przenośne do ustalonej lokalizacji, zajmujemy się montażem i obejmujemy je kompleksową obsługą serwisową. Wynajem i serwis wolnostojących ubikacji to usługa skierowana do podmiotów działających na terenie całego Braniewa, w tym dzielnic – Koźlin i Nowe Miasto Braniewo.</p>
                    <h3>Gdzie przydadzą się toalety przenośne w Braniewie?</h3>
                    <div className="city__mainContent--list">
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>PLACE BUDOWY,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>IMPREZY MASOWE (NP. FESTYNY, KONCERTY, WYDARZENIA SPORTOWE, OBCHODY ROCZNICOWE),</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>MIEJSCA ZBIOROWEGO WYPOCZYNKU (BIWAKI, KEMPINGI, OBOZY, KOLONIE, POLA NAMIOTOWE),</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>MIEJSCA W POBLIŻU ATRAKCJI TURYSTYCZNYCH,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>MIEJSCA ORGANIZACJI PRYWATNYCH EVENTÓW W PLENERZE (IMPREZY FIRMOWE, OKOLICZNOŚCIOWE),</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>PARKI, PUBLICZNE PLACE ZABAW, PLENEROWE STREFY WORKOUT,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>LOKALE I OGRÓDKI GASTRONOMICZNE,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>OGRÓDKI DZIAŁKOWE,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>PARKINGI.</p>
                        </div>
                    </div>
                    <h3>Toalety przenośne (Braniewo) – oferta dopasowana do Twoich indywidualnych potrzeb</h3>
                    <p>Toalety przenośne wynajmowane przez firmę Serwistoalet.pl – cechują się solidną i stabilną konstrukcją, atrakcyjnym designem i wysokim walorem użytkowym. Toalety przenośne (Braniewo) mają podwyższoną odporność na mechaniczne uszkodzenia, a tworzywo, z jakiego zostały wykonane – jest w pełni wytrzymałe na działanie niekorzystnych czynników pogodowych (wilgoć, duże wahania temperaturowe, promieniowanie UV itp.). Dzięki temu, nasze mobilne toalety z powodzeniem mogą być eksploatowane przez cały rok.</p>
                    <p>Oferowane przez nas przenośne toalety, posiadają podwójny system wentylacyjny, antypoślizgową podłogę, drzwi o zamkniętym profilu ze znacznikiem „wolne/zajęte”, uchwyt na papier toaletowy oraz deskę sedesową. Kabiny WC można wyposażyć dodatkowo m.in. w umywalkę z 40-litrowym zbiornikiem na wodę i dozownik ze środkiem dezynfekcyjnym do rąk. W ofercie posiadamy także przenośne toalety dla niepełnosprawnych.</p>
                    <h3>Zapraszamy do współpracy firmy, instytucje, urzędy i osoby fizyczne z całego Braniewa!</h3>
                </div>
            </div>
            <div className="city__benefits--wrapper">
                <Benefits />
            </div>
            <div className="city__last_red_baner--wrapper">
                <div>
                    <OrderRedBaner />
                </div>
            </div>
            <Footer />
            <Localizations />
            <Copyright />
        </div>

    )
}

export default Braniewo