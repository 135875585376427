import React, { useEffect } from 'react'
import '../../styles/localizations_city.css';
import { Images } from '../../assets/images/index.js'
import Header from '../Header'
import Navigation from '../Navigation'
import Copyright from '../Copyright'
import Footer from '../Footer'
import Benefits from '../Benefits'
import Localizations from '../Localizations'
import OrderRedBaner from '../Misc/Order_red_baner'

const Jantar = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    })

    return (
        <div className="city--wrapper">
            <Header />
            <Navigation />
            <div className="city--baner">
                <img src={Images.Top_baner} alt="top_baner"></img>
                <div className="city--baner__filter">
                    <h1>SERWIS</h1>
                    <h2>JANTAR</h2>
                </div>
            </div>
            <div className="city--mainContent">
                <div className="city--imgWrapper">
                    <img src={Images.PartyImg} alt='party_img' />
                    <div className="city--red_baner">
                        <OrderRedBaner />
                    </div>
                </div>
                <div className="city--description">
                    <h3>Praktyczne i tanie toalety przenośne – Jantar i okolice</h3>
                    <p>Przedsiębiorstwo Serwistoalet.pl od dawna zajmuje się świadczeniem profesjonalnych usług asenizacyjnych oraz oferuje na wynajem krótko i długoterminowy praktyczne i tanie toalety przenośne. Jantar to jedna z wielu miejscowości w województwie pomorskim, w którym posiadamy szerokie grono odbiorców (osoby prywatne, firmy, urzędy, zarządcy publicznych terenów itd.). Od lat stawiamy na najwyższy poziom obsługi, dbamy o satysfakcję naszych klientów i proponujemy bardzo korzystne warunki cenowe.</p>
                    <p>Na naszej firmowej stronie www znajdziecie specjalny formularz online do składania zamówień, który wspiera w zidentyfikowaniu i wyskalowaniu potrzeb, co pozwala w idealnym dopasowaniu oferty do oczekiwań. Zamówienia przyjmujemy są w przez całą dobę (24/7), z terminem realizacji najpóźniej do 12 h. Kabiny WC w Jantarze dostarczamy pod wskazany adres, razem z rozładunkiem, montażem (opcja zakotwienia) i zapewnieniem okresowego serwisu w trwania umowy  wynajmu na przenośne toalety. Serwis kabin obejmuje każdorazowo umycie, dezynfekcję oraz aplikację płynów eksploatacyjnych i uzupełnienie środków higienicznych (papier toaletowy, mydło, woda). Działamy na terenie całego Jantaru.</p>
                    <h3>Gdzie przydadzą się toalety przenośne w Jantarze?</h3>
                    <div className="city__mainContent--list">
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt='party_img'/>
                            <p>PLACE BUDOWY,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt='party_img'/>
                            <p>IMPREZY MASOWE (NP. FESTYNY, KONCERTY, WYDARZENIA SPORTOWE, OBCHODY ROCZNICOWE),</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt='party_img'/>
                            <p>MIEJSCA ZBIOROWEGO WYPOCZYNKU (BIWAKI, KEMPINGI, OBOZY, KOLONIE, POLA NAMIOTOWE),</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt='party_img'/>
                            <p>MIEJSCA W POBLIŻU ATRAKCJI TURYSTYCZNYCH,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt='party_img'/>
                            <p>MIEJSCA ORGANIZACJI PRYWATNYCH EVENTÓW W PLENERZE (IMPREZY FIRMOWE, OKOLICZNOŚCIOWE),</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt='party_img'/>
                            <p>PARKI, PUBLICZNE PLACE ZABAW, PLENEROWE STREFY WORKOUT,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt='party_img'/>
                            <p>LOKALE I OGRÓDKI GASTRONOMICZNE,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt='party_img'/>
                            <p>OGRÓDKI DZIAŁKOWE,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt='party_img'/>
                            <p>PARKINGI.</p>
                        </div>
                    </div>
                    <h3>Mobilne ubikacje (Jantar) – oferta dopasowana do potrzeb</h3>
                    <p>Funkcjonalne i mobilne ubikacje są bardzo solidne i stabilne – bez względu na rodzaj nawierzchni, na jakiej będą posadowione. Dobrze sprawdzają się zarówno na budowie, na imprezie, przy plaży, jak i na terenie pola namiotowego. Nasze przenośne toalety posiadają się mocną konstrukcję, dzięki czemu są odporne na uszkodzenia mechaniczne. Z powodzeniem można je użytkować o każdj porze roku, bowiem materiały, z jakich wykonane zostały wolnostojące ubikacje – są odporne na wilgoć czy duże wahania temperatur. </p>
                    <p>Oferowane przez naszą firmę mobilne toalety, wyposażone są w podwójny system wentylacji, a w zbiorniku bezodpływowym umieszczane są specjalne środki eliminujące nieprzyjemne zapachy. Oprócz zamykanych od środka drzwi, sedesu, uchwytu na papier czy antypoślizgowej podłogi, nasze toalety przenośne mogą posiadać dodatkowo umywalkę ze zbiornikiem wody, mydło w płynie czy oświetlenie. W razie potrzeby, dostarczamy też toalety dostosowane do użytku przez osoby niepełnosprawne ruchowo.</p>
                    <h3>Zapraszamy do współpracy firmy, instytucje, urzędy i osoby fizyczne z całego Jantaru!</h3>
                </div>
            </div>
            <div className="city__benefits--wrapper">
                <Benefits />
            </div>
            <div className="city__last_red_baner--wrapper">
                <div>
                    <OrderRedBaner />
                </div>
            </div>
            <Footer />
            <Localizations />
            <Copyright />
        </div>

    )
}

export default Jantar