import React, { useEffect } from 'react'
import '../../styles/localizations_city.css';
import { Images } from '../../assets/images/index.js'
import Header from '../Header'
import Navigation from '../Navigation'
import Copyright from '../Copyright'
import Footer from '../Footer'
import Benefits from '../Benefits'
import Localizations from '../Localizations'
import OrderRedBaner from '../Misc/Order_red_baner'

const Stegna = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    })

    return (
        <div className="city--wrapper">
            <Header />
            <Navigation />
            <div className="city--baner">
                <img src={Images.Top_baner} alt="top_baner"></img>
                <div className="city--baner__filter">
                    <h1>SERWIS</h1>
                    <h2>STEGNA</h2>
                </div>
            </div>
            <div className="city--mainContent">
                <div className="city--imgWrapper">
                    <img src={Images.PartyImg} alt='party_img' />
                    <div className="city--red_baner">
                        <OrderRedBaner />
                    </div>
                </div>
                <div className="city--description">
                    <h3>Estetyczne i funkcjonalne toalety przenośne – Stegna i okolice</h3>
                    <p>Firma Serwistoalet.pl od lat zaopatruje podmioty z województwa pomorskiego w tanie toalety przenośne. Stegna jest jedną w wielu miejscowości w północnym rejonie kraju, w których nasza firma dostarcza swoje usługi. Oferujemy krótko- oraz długookresowy wynajem toalet przenośnych na terenie Stegny – zarówno dla prywatnych klientów, przedsiębiorstw, jak i zarządców przestrzeni publicznych, gwarantując przy tym wysoką jakość usług, bardzo atrakcyjne ceny i rabaty dla stałych partnerów.</p>
                    <p>Na stronie serwistoalet.pl udostępniliśmy łatwy w obsłudze formularz interaktywny, dzięki któremu można określić swoje potrzeby i złożyć zamówienie. Wszystkie zlecenia wykonywane są maksymalnie do 12 godzin od momentu wysłania zapotrzebowania i załatwieniu formalności. Nasze funkcjonalne toalety przenośne to doskonała alternatywa do stworzenia plenerowego zaplecza sanitarnego – wszędzie tam, gdzie brak jest dostępu do toalet stacjonarnych. Mobilne kabiny WC dostarczamy pod wskazany adres i instalujemy w ustalonej lokalizacji, bez względu na warunki terenowe (nawierzchnia ziemna, trawiasta, betonowa). Serwistoalet.pl zajmuje się świadczeniem usług na terenie całej Stegny.</p>
                    <h3>Gdzie przydadzą się toalety przenośne w Stegnie?</h3>
                    <div className="city__mainContent--list">
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>PLACE BUDOWY,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>IMPREZY MASOWE (NP. FESTYNY, KONCERTY, WYDARZENIA SPORTOWE, OBCHODY ROCZNICOWE),</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>MIEJSCA ZBIOROWEGO WYPOCZYNKU (BIWAKI, KEMPINGI, OBOZY, KOLONIE, POLA NAMIOTOWE),</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>MIEJSCA W POBLIŻU ATRAKCJI TURYSTYCZNYCH,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>MIEJSCA ORGANIZACJI PRYWATNYCH EVENTÓW W PLENERZE (IMPREZY FIRMOWE, OKOLICZNOŚCIOWE),</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>PARKI, PUBLICZNE PLACE ZABAW, PLENEROWE STREFY WORKOUT,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>LOKALE I OGRÓDKI GASTRONOMICZNE,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>OGRÓDKI DZIAŁKOWE,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>PARKINGI.</p>
                        </div>
                    </div>
                    <h3>Przenośne toalety (Stegna) – oferta spersonalizowana do potrzeb</h3>
                    <p>Przenośne toalety, jakie wynajmuje firma Serwistoalet.pl, to solidne i stabilne kabiny, zapewniające użytkownikom pełen komfort i bezpieczeństwo. Kabiny WC wykonane są z najwyższej jakości tworzywa, dzięki czemu są wytrzymałe na mechaniczne uszkodzenia i odporne na najbardziej niesprzyjające nawet warunki pogodowe. Toalety wyposażone są w dwa niezależne systemy wentylacji, w środku znajduje się podłoga antypoślizgowa, uchwyt na papier toaletowy, pisuar/deska sedesowa oraz pojemny zbiornik bezodpływowy (265 litrów). Opcjonalnie, toalety mobilne mogą zostać wyposażone m.in. w umywalkę ze zbiornikiem wody oraz dozownik ze środkiem dezynfekującym do dłoni. Wszystkie kabiny są przez nas regularnie serwisowane w okresie trwania wynajmu, przez co zawsze pozostają czyste i gotowe do użytku.</p>
                    <h3>Zapraszamy do współpracy firmy, instytucje, urzędy i osoby fizyczne z całej Stegny!</h3>
                </div>
            </div>
            <div className="city__benefits--wrapper">
                <Benefits />
            </div>
            <div className="city__last_red_baner--wrapper">
                <div>
                    <OrderRedBaner />
                </div>
            </div>
            <Footer />
            <Localizations />
            <Copyright />
        </div>

    )
}

export default Stegna