import '../../styles/order_red_baner.css'
import { Link } from 'react-router-dom'
import { FaComments } from "react-icons/fa"

const OrderSanitationBaner = ()=> (
    <div className="red_baner--wrapper">
        <FaComments className="red_baner--icon"/>
        <Link to='/sanitationOrder'>Zamów wywóz</Link>
    </div>
)

export default OrderSanitationBaner