import React from 'react'
import { useLoadScript } from '@react-google-maps/api'
import Map from './Map'

const GoogleMap = () => {

    const { isLoaded } = useLoadScript ({ 
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    })

    if(!isLoaded) return <div className="map--loading">Loading...</div>

    return <Map />

}

export default GoogleMap