import React, { useEffect } from 'react'
import '../../styles/localizations_city.css';
import { Images } from '../../assets/images/index.js'
import Header from '../Header'
import Navigation from '../Navigation'
import Copyright from '../Copyright'
import Footer from '../Footer'
import Benefits from '../Benefits'
import Localizations from '../Localizations'
import OrderRedBaner from '../Misc/Order_red_baner'

const Ndgdanski = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    })

    return (
        <div className="city--wrapper">
            <Header />
            <Navigation />
            <div className="city--baner">
                <img src={Images.Top_baner} alt="top_baner"></img>
                <div className="city--baner__filter">
                    <h1>SERWIS</h1>
                    <h2>NOWY DWÓR GDAŃSKI</h2>
                </div>
            </div>
            <div className="city--mainContent">
                <div className="city--imgWrapper">
                    <img src={Images.PartyImg} alt='party_img'/>
                    <div className="city--red_baner">
                        <OrderRedBaner />
                    </div>
                </div>
                <div className="city--description">
                    <h3>Uniwersalne toalety przenośne – Nowy Dwór Gdański i okolice</h3>
                    <p>Firma Serwistoalet.pl wspiera podmioty z województwa pomorskiego w organizacji zaplecza sanitarnego, dostarczając uniwersalne toalety przenośne. Nowy Dwór Gdański i okolice od lat należą do obszaru naszej aktywności biznesowej. Oferujemy krótko- lub długookresowy wynajem toalet przenośnych na terenie Nowego Dworu Gdańskiego – dla klientów indywidualnych, firm i instytucji, zapewniając przy tym wysoką jakość usług oraz bardzo konkurencyjne ceny. </p>
                    <p>Na stronie internetowej serwistoalet.pl udostępniliśmy intuicyjny formularz online do składania zamówień, które zawsze realizujemy w błyskawicznym tempie (do 12 h). Solidne przenośne toalety sprawdzą się wszędzie tam, gdzie nie ma łatwego dostępu do stacjonarnego zaplecza sanitarnego i/lub instalacji wodno-kanalizacyjnej. Z chęcią dostarczymy mobilne kabiny WC pod wskazany adres i w uzgodnionym terminie oraz zapewnimy profesjonalny serwis w czasie trwania wynajmu. Obsługujemy cały Nowy Dwór Gdański, a także Żelichowo. </p>
                    <h3>Gdzie przydadzą się toalety przenośne w Nowy Dwór Gdański?</h3>
                    <div className="city__mainContent--list">
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>PLACE BUDOWY,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>IMPREZY MASOWE (NP. FESTYNY, KONCERTY, WYDARZENIA SPORTOWE, OBCHODY ROCZNICOWE),</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>MIEJSCA ZBIOROWEGO WYPOCZYNKU (BIWAKI, KEMPINGI, OBOZY, KOLONIE, POLA NAMIOTOWE),</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>MIEJSCA W POBLIŻU ATRAKCJI TURYSTYCZNYCH,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>MIEJSCA ORGANIZACJI PRYWATNYCH EVENTÓW W PLENERZE (IMPREZY FIRMOWE, OKOLICZNOŚCIOWE),</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>PARKI, PUBLICZNE PLACE ZABAW, PLENEROWE STREFY WORKOUT,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>LOKALE I OGRÓDKI GASTRONOMICZNE,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>OGRÓDKI DZIAŁKOWE,</p>
                        </div>
                        <div className="city__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>PARKINGI.</p>
                        </div>
                    </div>
                    <h3>Mobilne toalety (Nowy Dwór Gdański) – oferta dopasowana do potrzeb</h3>
                    <p>Mobilne toalety wynajmowane przez Serwistoalet.pl, posiadają solidną konstrukcję, odznaczają się wysoką estetyką wykonania, a poza tym – można je mocować na wszystkich rodzajach podłoża (asfalt/beton, ziemne, trawiaste). Toalety przenośne na budowy czy imprezy w Nowy Dwór Gdański – wytworzone zostały z wysokiej klasy tworzyw sztucznych, które są odporne na ekstremalnie niekorzystne czynniki pogodowe (opady deszczu/śniegu, silny wiatr, mrozy, upały czy intensywne nasłonecznienie). Kabiny WC posiadają podwójny system wentylacyjny, antypoślizgową podłogę, ryglowane od wewnątrz drzwi, półprzeźroczysty daszek oraz zbiornik bezodpływowy o pojemności 265 l (ok. 700 wizyt). Przenośne toalety mogą być wyposażone również w umywalki ze zbiornikiem i zapasem wody, deskę sedesową, pisuar oraz dozownik ze środkiem dezynfekcyjnym do rąk.</p>
                    <h3>Zapraszamy do współpracy firmy, instytucje, urzędy i osoby fizyczne z całego Nowego Dworu Gdańskiego!</h3>
                </div>
            </div>
            <div className="city__benefits--wrapper">
                <Benefits />
            </div>
            <div className="city__last_red_baner--wrapper">
                <div>
                    <OrderRedBaner />
                </div>
            </div>
            <Footer />
            <Localizations />
            <Copyright />
        </div>

    )
}

export default Ndgdanski