import React, {useEffect} from 'react'
import '../styles/offert.css'
import { Images } from '../assets/images/index.js'
import Header from './Header'
import Navigation from './Navigation'
import Copyright from './Copyright'
import Footer from './Footer'
import Benefits from './Benefits'
import OrderRedBaner from './Misc/Order_red_baner'
import Localizations from './Localizations'

const OffertOnParty = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    })

    return (
        <div className="offert-wrapper">
        <Header />
        <Navigation />
        <div className="offert--baner">
            <img src={Images.Top_baner} alt="top_baner"></img>
            <div className="offert--baner__filter">
                <h1 className="offert--partyTitle">TOALETY</h1>
            </div>
        </div>
        <div className="offert--mainContent">
            <div className="offert--imgWrapper">
                <img src={Images.PartyImg} alt='party_img' />
                <div className="offert--red_baner">
                    <OrderRedBaner />
                </div>
            </div>
            <div className="offert--description">
                <h3>Czyste i wygodne toalety na imprezy – zadbaj o najwyższy standard dla swoich gości!</h3>
                <p>W ramach szerokiej palety usług, oferujemy wynajem przenośnych toalet na imprezę o charakterze masowym, wydarzenia kameralne oraz na prywatne eventy (np. wesela, branżowe rozgrywki sportowe, imprezy firmowe itd.). Zgodnie z obowiązującymi przepisami, ale przede wszystkim w trosce o komfort uczestników imprezy, każdy organizator powinien zadbać o zapewnienie gościom dostępu do strefy sanitarnej. Jeśli chodzi o wydarzenia pod gołym niebem, to niezawodnym rozwiązaniem najczęściej okazują się mobilne toalety na imprezy, które nie wymagają podpięcia do instalacji wodno-kanalizacyjnej. </p>
                <p>Firma Serwistoalet.pl specjalizuje się w kompleksowej obsłudze imprez sportowych, kulturalnych, rozrywkowych czy obchodów rocznicowych – w zakresie dostarczania i serwisowania nowoczesnych kabin WC. Wszystkie nasze toalety przenośne wykonane zostały z wysokiej klasy materiałów – odpornych na mechaniczne uszkodzenia, wilgoć i działanie ekstremalnych temperatur. Cechy te sprawiają, że mobilne ubikacje na imprezy można bez przeszkód użytkować całorocznie.</p>
                <h3>Mobilne kabiny WC na imprezy – oferta firmy Serwistoalet.pl</h3>
                <p>Funkcjonalne i mobilne kabiny WC na imprezy od firmy Serwistoalet.pl – to nie tylko wysokiej jakości ubikacje, ale również gwarancja najwyższego standardu czystości oraz higieny. Podczas każdego serwisu, przenośne kabiny WC są dokładnie myte i dezynfekowane oraz zaopatrywane w specjalne środki ekologiczne, zapewniające przyjemny zapach przez cały czas użytkowania. Nasze toalety posiadają dwa systemy wentylacji, antypoślizgową podłogę, drzwi zamykane od wewnątrz (z oznakowaniem wolne/zajęte) oraz mogą być doposażone w umywalkę ze zbiornikiem wody do umycia dłoni.</p>
                <p>Zapewniamy dowóz, rozładunek i instalację toalet w ustalonym miejscu oraz terminie, a także ewentualny serwis kabiny WC w trakcie imprezy i jej odbiór po zakończeniu wynajmu. Na etapie zamówienia z chęcią doradzimy wybór konkretnego modelu, wyposażenia i ilości przenośnych toalet – uwzględniając specyfikę danej imprezy, czas trwania oraz przewidywaną frekwencję. Z profesjonalną usługą firmy Serwistoalet.pl – zadbasz o odpowiedni komfort uczestników, unikniesz gorszących widoków na terenie imprezy, problemów higieniczno-sanitarnych, a Twoi goście będą mogli skupić się wyłącznie na dobrej zabawie.</p>
            </div>
        </div>
        <div className="offert__benefits--wrapper">
            <Benefits />
        </div>
        <div className="offert__last_red_baner--wrapper">
            <div>
                <OrderRedBaner />
            </div>
        </div>
        <Footer />
        <Localizations />
        <Copyright />
    </div>
    )
}

export default OffertOnParty