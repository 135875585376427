import React, { useState, useEffect } from 'react'
import '../styles/order.css'
import { Images } from '../assets/images/index.js'
import Header from './Header'
import Navigation from './Navigation'
import Copyright from './Copyright'
import Footer from './Footer'
import Localizations from './Localizations'
import { HiOutlineBell } from 'react-icons/hi'
import FormCabins from './orderForm/FormCabins'
import FormPersonalInfo from './orderForm/FormPersonalInfo'
import FormSummary from './orderForm/FormSummary'
import FormEnding from './orderForm/FormEnding'
import { format, differenceInCalendarDays } from 'date-fns'

const Order = () => {

    const [page, setPage] = useState(0);
    let dateNow = new Date();
    const [startDate, setStartDate] = useState(new Date(dateNow))
    const [endDate, setEndDate] = useState(new Date())
    const [servicePrice, setServicePrice] = useState(0)
    const [accesoriesPrice, setAccesoriesPrice] = useState(0)
    const [orderStartDate, setOrderStartDate] = useState()
    const [orderEndDate, setOrderEndDate] = useState()

    const [order, setOrder] = useState({
        cabinsValue: 1,
        orderCity: '',
        orderPostcode: '',
        orderStreet: '',
        orderPlaceNr: '',
        orderContatNumber: '',
        orderService: 'Brak',
        orderWashbasin: 'nie',
        orderDispenser: 'nie',
        orderPrice: 0,
        orderStartingDate: '',
        orderEndingDate: '',
        companyName: '',
        nip:'',
        city:'',
        street:'',
        postCode: '',
        placeNr:'',
        localNr:'',
        email:'',
        pesel: '',
        correspName: '',
        correspCity: '',
        correspPostCode:'',
        correspStreet: '',
        correspPlaceNr: '',
        correspLocalNr: ''
    })

    const [checked, setChecked] = useState({
        fusionInput: true,
        mtmInput: false,
        serviceOneWeek: false,
        serviceTwoWeek: false,
        serviceFourWeek: false,
        washbasin: false,
        dispenser: false
    })

    const [clientType, setClientType] = useState({
        company: true,
        private: false
    })

    const [contactData, setContactData] = useState(false)

    const PageDisplay = () => {
        if (page === 0) {
            return <FormCabins page={page} setPage={setPage} order={order} setOrder={setOrder} dateNow={dateNow} startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} difference={difference} orderStartDate={orderStartDate} orderEndDate={orderEndDate} servicePrice={servicePrice} setServicePrice={setServicePrice} accesoriesPrice={accesoriesPrice} setAccesoriesPrice={setAccesoriesPrice} checked={checked} setChecked={setChecked} />
        }

        if (page === 1) {
            return <FormPersonalInfo page={page} setPage={setPage} order={order} setOrder={setOrder} orderStartDate={orderStartDate} orderEndDate={orderEndDate} checked={checked} setChecked={setChecked} clientType={clientType} setClientType={setClientType} contactData={contactData} setContactData={setContactData}/>
        }

        if (page === 2) {
            return <FormSummary page={page} setPage={setPage} order={order} orderStartDate={orderStartDate} orderEndDate={orderEndDate} checked={checked} clientType={clientType} contactData={contactData}/>
        }

        if (page === 3) {
            return <FormEnding page={page} setPage={setPage}/>
        }
    }

    const difference = differenceInCalendarDays(
        new Date(endDate),
        new Date(startDate)
    )

    useEffect(() => {
        setOrderStartDate(format(new Date(startDate), 'yyyy-MM-dd'))
        setOrderEndDate(format(new Date(endDate), 'yyyy-MM-dd'))
    }, [startDate, endDate])

    return (
        <div className="order--wrapper">
            <Header />
            <Navigation />
            <div className="order--baner">
                <img src={Images.Top_baner} alt="top_baner"></img>
                <div className="order--baner__filter">
                    <h1>ZAMÓWIENIE</h1>
                </div>
            </div>
            <div className="order--mainContent">
                <div className="order__mainContent--header"
                    style={{ display: page === 0 ? "flex" : "none" }}>
                    <h3>ZAMÓW</h3>
                    <div className="mainContent__header--info">
                        <div className="mainContent__header--infoWrapper">
                            <div>
                                <p>BRAK <b>ZBĘDNYCH FORMALNOŚCI</b></p>
                            </div>
                            <div>
                                <p>DOWÓZ W CIĄGU <b>2 DNI ROBOCZYCH</b></p>
                            </div>
                            <div>
                                <p>PROFESJONALNY SERWIS</p>
                            </div>
                            <div>
                                <p>DOWÓZ W CIĄGU <b>12H OD ZAMÓWIENIA*</b> </p>
                            </div>
                        </div>
                    </div>
                    <div className="mainContent__header--lastInfo">
                        <div className="bell__container">
                            <HiOutlineBell className="bell__ico" />
                        </div>
                        <div className="mainContent__header--description">
                            <p>Przez ten formularz możesz zamówić toalety, jeśli <b>potrzebujesz innych rozwiązań</b> lub <b>masz pytania</b> dotyczące zamawiania toalet to zapraszamy do kontaktu: <b>733 435 435, biuro@serwistoalet.pl</b></p>
                            <p><i>Zdjęcia toalet są poglądowe</i></p>
                            <p><i>* za dodatkową opłatą</i></p>
                        </div>
                    </div>
                </div>
                <div className='order--form'>
                    {PageDisplay()}
                </div>
            </div>
            <Footer />
            <Localizations />
            <Copyright />
        </div>
    )
}

export default Order