import React, { useEffect } from 'react'
import { MdShoppingCart } from "react-icons/md"

const SanitationSummary = ({clientType, setClientType, sanitationOrder}) => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const bascetcase = <MdShoppingCart size="60px" />

    return (<div className="formSummary--wrapper">
        <div className="form__steps--third">
            <div></div>
            <div>
                <p>KROK 1</p>
                <p>
                    Zamówienie
                </p>
            </div>
            <div>
                <p>KROK 2</p>
                <p>
                    Uzupełnij <br />
                    swoje dane
                </p>
            </div>
            <div>
                <p>KROK 3</p>
                <p>
                    Podsumowanie <br />
                    sprawdź informacje
                </p>
            </div>
            <div>
                <p>KROK 4</p>
                <p>
                    Potwierdzenie <br />
                    Twojego zamówienia
                </p>
            </div>
            <div></div>
        </div>
        <div className="formSummary--content">
            <h3>PODSUMOWANIE</h3>
            <div className="formSummary__content--wrapper">
                <div className="formSummary--orderSummary">
                    <div>
                        <label>ILOŚĆ m<sup>3</sup></label>
                        <p> {sanitationOrder.sanitationValue} m<sup>3</sup></p>
                    </div>
                    <div>
                        <label>ADRES ODBIORU NIECZYSTOŚCI:</label>
                        <p>
                        ul. {sanitationOrder.orderStreet} {sanitationOrder.orderPlaceNr}, {sanitationOrder.orderPostcode} {sanitationOrder.orderCity}
                        </p>
                    </div>
                    <div>
                        <label>NUMER KONTAKTOWY:</label>
                        <p>{sanitationOrder.orderContactNumber}</p>
                    </div>
                    <div>
                        <label>PROPONOWANA DATA WYWOZU:</label>
                        <p>
                        {sanitationOrder.orderDate}
                        </p>
                    </div>
                </div>
                <div className="formSummary--personalData">
                    <div
                        style={{ display: clientType.company === true ? "flex" : "none" }}
                    >
                        <label>NAZWA FIRMY:</label>
                        <p> {sanitationOrder.companyName}</p>
                    </div>
                    <div
                        style={{ display: clientType.private === true ? "flex" : "none" }}
                    >
                        <label>IMIĘ I NAZWISKO:</label>
                        <p> {sanitationOrder.companyName}</p>
                    </div>
                    <div
                        style={{ display: clientType.company === true ? "flex" : "none" }}
                    >
                        <label>NIP:</label>
                        <p> {sanitationOrder.nip} </p>
                    </div>
                    <div
                        style={{ display: clientType.private === true ? "flex" : "none" }}
                    >
                        <label>PESEL:</label>
                        <p> {sanitationOrder.pesel} </p>
                    </div>
                    <div>
                        <label>ADRES :</label>
                        <p>
                        ul. {sanitationOrder.street} {sanitationOrder.placeNr}/{sanitationOrder.localNr}, {sanitationOrder.postCode} {sanitationOrder.city} 
                        </p>
                    </div>
                    <div>
                        <label>EMAIL:</label>
                        <p> {sanitationOrder.email} </p>
                    </div>
                    <div
                        className="formSummary__correspAdress--wrapper"
                        // style={{ display: contactData === true ? "flex" : "none" }}
                    >
                        <div className="formSummary__correspAdress--header">
                            {/* {inputChecked} */}
                            <p> Dodatkowy adres korespondencyjny:</p>
                        </div>
                        <div>
                            <label>IMIĘ I NAZWISKO:</label>
                            <p> {sanitationOrder.correspName} </p>
                        </div>
                        <div>
                            <label>ADRES :</label>
                            <p>
                            ul. {sanitationOrder.correspStreet} {sanitationOrder.correspPlaceNr} / {sanitationOrder.correspLocalNr}, {sanitationOrder.correspPostCode} {sanitationOrder.correspCity} 
                            </p>
                        </div>
                    </div>
                    <div className="formSummary--price">
                        <h3>CENA</h3>
                        <div className="formSummary__price--price">
                            <div>{bascetcase}</div>
                            <div>
                                <p>
                                    <b>{}</b> zł brutto
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="next__button--wrapper">
                <p>krok: 3 z 4</p>
                <button className="order--nextButton">
                    DALEJ
                </button>
            </div>
        </div>
    </div>)
}

export default SanitationSummary