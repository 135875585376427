import '../styles/map.css';
import { 
    GoogleMap,
    Marker
} from '@react-google-maps/api'

const Map = () => {

    return <div className="map">
        <GoogleMap 
            zoom={18}
            center={{ lat: 54.1706061, lng: 19.397763938641365}}
            mapContainerClassName="mapContainer--container"
            options={{ 
                gestureHandling: 'cooperative', 
                disableDefaultUI: true
            }}
        >
        <Marker 
            position={{ lat: 54.1706061, lng: 19.397763938641365}}
        />
        </GoogleMap>
    </div>
}
 
export default Map