import React, { useEffect } from 'react'
import '../styles/offert.css'
import { Images } from '../assets/images/index.js'
import Header from './Header'
import Navigation from './Navigation'
import Copyright from './Copyright'
import Footer from './Footer'
import Benefits from './Benefits'
import OrderRedBaner from './Misc/Order_red_baner'
import Localizations from './Localizations'

const OffertOnEvents = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    })

    return (
        <div className="offert-wrapper">
            <Header />
            <Navigation />
            <div className="offert--baner">
                <img src={Images.Top_baner} alt="top_baner"></img>
                <div className="offert--baner__filter">
                    <h1 className="offert--eventsTitle">TOALETY</h1>
                </div>
            </div>
            <div className="offert--mainContent">
                <div className="offert--imgWrapper">
                    <img src={Images.EventsImg} alt='baner_events' />
                    <div className="offert--red_baner">
                        <OrderRedBaner />
                    </div>
                </div>
                <div className="offert--description">
                    <h3>Mobilne toalety na inne okazje – gdzie jeszcze sprawdzą się uniwersalne kabiny WC?</h3>
                    <p>Mobilne toalety to świetne rozwiązanie nie tylko dla pracowników budowlanych czy uczestników imprez masowych, bowiem doskonale sprawdzą się także w innych miejscach, w których nie ma dostępu do stacjonarnego zaplecza sanitarnego. Wolnostojące kabiny WC ze zbiornikiem bezodpływowym – to gwarancja czystości, higieny i komfortu – wszędzie tam, gdzie brak jest instalacji wodno-kanalizacyjnej. Pomagają również w zachowaniu odpowiedniego porządku – zarówno w miejscach publicznych, jak i na terenach prywatnych. Funkcjonalne toalety przenośne doskonale sprawdzają się w takich miejscach, jak:</p>
                    <div className="offert__mainContent--list">
                        <div className="offert__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>BIWAKI, KEMPINGI, OBOZY/KOLONIE,</p>
                        </div>
                        <div className="offert__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>POLA NAMIOTOWE,</p>
                        </div>
                        <div className="offert__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>ATRAKCJE TURYSTYCZNE, PLAŻE,</p>
                        </div>
                        <div className="offert__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>PARKI REKREACYJNE, </p>
                        </div>
                        <div className="offert__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>PLACE ZABAW, PLENEROWE STREFY REKREACJI RUCHOWEJ (WORKOUT),</p>
                        </div>
                        <div className="offert__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>LOKALE SEZONOWE I OGRÓDKI GASTRONOMICZNE,</p>
                        </div>
                        <div className="offert__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>LOKALE I OGRÓDKI GASTRONOMICZNE,</p>
                        </div>
                        <div className="offert__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>OGRÓDKI DZIAŁKOWE,</p>
                        </div>
                        <div className="offert__mainContent--line">
                            <img src={Images.LiIcon} alt="LiIcon"/>
                            <p>PARKINGI.</p>
                        </div>
                    </div>
                    <p>Przenośne ubikacje wykonane są wysokiej klasy tworzyw sztucznych, odpornych na czynniki atmosferyczne, takie jak: wilgoć, duże wahania temperatur czy intensywne nasłonecznienie. Solidna i przemyślana konstrukcja zapewnia kabinom WC idealną stabilność, z powodzeniem można je montować na dowolnym rodzaju podłoża. Nasze toalety przenośne odznaczają się nowoczesnym designem, wyposażone są w podwójną wentylację, podłogę antypoślizgową, mogą posiadać sedes, pisuar i umywalkę ze zbiornikiem wody. </p>
                    <p>W ramach każdego serwisu, kabiny są dokładnie myte i dezynfekowane, uzupełniamy też zapas papieru toaletowego oraz ekologicznych środków myjących w dozowniku. Uniwersalne ubikacje typu toi-toi oraz kompleksowa usługa świadczona przez firmę Serwistoalet.pl – to zawsze gwarancja czystości i porządku na najwyższym poziomie!</p>
                </div>
            </div>
            <div className="offert__benefits--wrapper">
                <Benefits />
            </div>
            <div className="offert__last_red_baner--wrapper">
                <div>
                    <OrderRedBaner />
                </div>
            </div>
            <Footer />
            <Localizations />
            <Copyright />
        </div>
    )
}

export default OffertOnEvents