import React, { useEffect } from "react"
import '../../styles/sanitationForm/sanitationService.css'
import '../../styles/order.css'
import Calendar from 'react-calendar'
import InputMask from "react-input-mask"
import { IoStorefront } from 'react-icons/io5'
import { MdShoppingCart } from 'react-icons/md'
import SanitationGoogleMap from './sanitationFormMap/SanitationGoogleMap'

const SanitationService = ({ dateNow, sanPage, setSanPage, sanitationOrder, setSanitationOrder, sanitationDate, setSanitationDate, sanitationOrderDate, setSanitationOrderDate }) => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const shopIcon = <IoStorefront size='60px' />
    const bascetcase = <MdShoppingCart size='60px' />

    const changeSubmitSanForm = (e) => {
        e.preventDefault()
        setSanPage((currSanPage) => currSanPage + 1)
    }

    return (

        <form className="sanitationService--wrapper" onSubmit={changeSubmitSanForm}>
            <div className="form__steps--first">
                <div></div>
                <div>
                    <p>KROK 1</p>
                    <p>Zamówienie</p>
                </div>
                <div>
                    <p>KROK 2</p>
                    <p>Uzupełnij <br />swoje dane</p></div>
                <div>
                    <p>KROK 3</p>
                    <p>Podsumowanie <br />sprawdź informacje</p></div>
                <div>
                    <p>KROK 4</p>
                    <p>Potwierdzenie <br />Twojego zamówienia</p></div>
                <div></div>
            </div>
            <div className="sanitationService--form">
                <div className="sanitationService__form--type">
                    <h3>RODZAJ</h3>
                    <select
                        value={sanitationOrder.sanitationType}
                        onChange={(e) => {
                            setSanitationOrder({ ...sanitationOrder, sanitationType: e.target.value })
                        }}
                    >
                        <option>Nieczystości bytowe</option>
                        <option>Oczyszczalnia EKO</option>
                    </select>
                    <h3>ILOŚĆ </h3>
                    <input
                        type="number"
                        id="cabinsValueInput"
                        placeholder='Podaj objętość nieczystości w m3'
                        min={1}
                        value={sanitationOrder.sanitationValue}
                        onChange={(e) => {
                            setSanitationOrder({ ...sanitationOrder, sanitationValue: e.target.value })
                        }}
                    // required
                    />
                    <h3>DATA</h3>
                    <Calendar
                        minDate={dateNow}
                        value={sanitationDate}
                        onChange={setSanitationDate}
                    />
                </div>
                <div className="sanitationService__form--addres">
                    <h3>ADRES</h3>
                    <div>
                        <input
                            type="text"
                            placeholder="Miejscowość"
                            value={sanitationOrder.orderCity}
                            onChange={(e) => {
                                setSanitationOrder({ ...sanitationOrder, orderCity: e.target.value })
                            }}
                        // required
                        />
                        <InputMask
                            type="text"
                            placeholder="Kod pocztowy"
                            max={6}
                            mask="99-999"
                            value={sanitationOrder.orderPostcode}
                            onChange={(e) => {
                                setSanitationOrder({ ...sanitationOrder, orderPostcode: e.target.value });
                            }}
                        // required
                        />
                    </div>
                    <div>
                        <input
                            type="text"
                            placeholder="Ulica"
                            value={sanitationOrder.orderStreet}
                            onChange={(e) => {
                                setSanitationOrder({ ...sanitationOrder, orderStreet: e.target.value })
                            }}

                        // required
                        />
                        <input
                            type="text"
                            placeholder="Nr"
                            value={sanitationOrder.orderPlaceNr}
                            onChange={(e) => {
                                setSanitationOrder({ ...sanitationOrder, orderPlaceNr: e.target.value })
                            }}
                        // required
                        />
                    </div>
                    <input
                        className="input--phone"
                        type="text"
                        placeholder="Numer kontaktowy"
                        minLength='9'
                        maxLength='9'
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                        value={sanitationOrder.orderContactNumber}
                        onChange={(e) => {
                            setSanitationOrder({ ...sanitationOrder, orderContactNumber: e.target.value })
                        }}
                    // required
                    />
                    <div className="sanitationService__form--map">
                        <SanitationGoogleMap sanitationOrder={sanitationOrder} setSanitationOrder={setSanitationOrder}/>
                    </div>
                    <div>
                        <label>Dystans: 124 km</label>
                    </div>

                </div>
                <div className='emptyContainer'></div>
                <div className="cabinsForm__price--wrapper">
                    <div className="cabinsForm__price--icon">
                        {shopIcon}
                    </div>
                    <h3>CENA </h3>
                    <div className="cabinsForm__price--price">
                        <div>{bascetcase}</div>
                        <div>
                            <p>159</p>
                            <p>zł brutto</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='next__button--wrapper'>
                <p>krok: 1 z 4</p>
                <button type='submit' className="order--nextButton">DALEJ</button>
            </div>
        </form>
    )
}

export default SanitationService;