import React from 'react'
import '../styles/localizations.css'
import { Link } from 'react-router-dom'

const Localizations = () => (

    <div className="localizations--wrapper">
        <div className="localizations--mainContainer">
        <h2>Lokalizacje:</h2>
            <div className="localizations--linkWrapper">
                <div className="localizations--linkContainer">
                    <Link to='/localization/elblag'>ELBLĄG</Link>
                </div>
                <div className="localizations--linkContainer">
                    <Link to='/localization/gdansk'>GDAŃSK</Link>
                </div>
                <div className="localizations--linkContainer">
                    <Link to='/localization/gdynia'>GDYNIA</Link>
                </div>
                <div className="localizations--linkContainer">
                    <Link to='/localization/ostroda'>OSTRÓDA</Link>
                </div>
                <div className="localizations--linkContainer">
                    <Link to='/localization/nowy_dwor_gdanski'>NOWY DWÓR GDAŃSKI</Link>
                </div>
                <div className="localizations--linkContainer">
                    <Link to='/localization/tczew'>TCZEW</Link>
                </div>
                <div className="localizations--linkContainer">
                    <Link to='/localization/paslek'>PASŁĘK</Link>
                </div>
                <div className="localizations--linkContainer">
                    <Link to='/localization/malbork'>MALBORK</Link>
                </div>
                <div className="localizations--linkContainer">
                    <Link to='/localization/braniewo'>BRANIEWO</Link>
                </div>
                <div className="localizations--linkContainer">
                    <Link to='/localization/morag'>MORĄG</Link>
                </div>
                <div className="localizations--linkContainer">
                    <Link to='/localization/stegna'>STEGNA</Link>
                </div>
                <div className="localizations--linkContainer">
                    <Link to='/localization/jantar'>JANTAR</Link>
                </div>
                <div className="localizations--linkContainer">
                    <Link to='/localization/pruszcz_gdanski'>PRUSZCZ GDAŃSKI</Link>
                </div>
                <div className="localizations--empty">
                </div>
            </div>
        </div>
    </div>
)

export default Localizations