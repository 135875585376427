import React, { useEffect } from 'react'
import { BsCheckSquareFill, BsCheckSquare } from 'react-icons/bs'
import { MdShoppingCart } from 'react-icons/md'
import InputMask from "react-input-mask";

const SanitationPersonalInfo = ({ clientType, setClientType, sanitationOrder, setSanitationOrder, contactData, setContactData, setSanPage }) => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const inputChecked = <BsCheckSquareFill size='40px' />
    const inputNotChecked = <BsCheckSquare size='40px' className='formPersonalInfo__notCheckedIcon' />
    const bascetcase = <MdShoppingCart size='60px' />

    const chandleClientType = (e) => {
        if (e.target === document.querySelector("#companyInput") && clientType.company !== true) {
            setClientType({ ...clientType, company: !clientType.company, private: !clientType.private })
        }

        if (e.target === document.querySelector("#privateInput") && clientType.private !== true) {
            setClientType({ ...clientType, company: !clientType.company, private: !clientType.private })
        }
    }

    const changeSubmitSanForm = (e) => {
        e.preventDefault()
        setSanPage((currSanPage) => currSanPage + 1)
    }

    return (
        <form className="formPersonalInfo--wrapper" onSubmit={changeSubmitSanForm}>
            <div className="form__steps--secound">
                <div></div>
                <div>
                    <p>KROK 1</p>
                    <p>Zamówienie</p>
                </div>
                <div>
                    <p>KROK 2</p>
                    <p>Uzupełnij <br />swoje dane</p></div>
                <div>
                    <p>KROK 3</p>
                    <p>Podsumowanie <br />sprawdź informacje</p></div>
                <div>
                    <p>KROK 4</p>
                    <p>Potwierdzenie <br />Twojego zamówienia</p></div>
                <div></div>
            </div>
            <div className="formPersonalInfo--form">
                <div className="formPersonalInfo__form--inputsWrapper">
                    <h3>DANE</h3>
                    <div className="formPersonalInfo__inputsHolder">
                        <div>
                            <input
                                type="radio"
                                id="companyInput"
                                name="setClientProfile"
                                value={clientType.company}
                                onChange={chandleClientType}
                            />
                            {clientType.company ? inputChecked : inputNotChecked}
                            <label>Działalność gospodarcza</label>
                        </div>
                        <div>
                            <input
                                type="radio"
                                id="privateInput"
                                name="setClientProfile"
                                value={clientType.private}
                                onChange={chandleClientType}
                            />
                            {clientType.private ? inputChecked : inputNotChecked}
                            <label>Osoba prywatna</label>
                        </div>
                    </div>
                    <div className="formPersonalInfo__form--companyForm"
                        style={{ display: clientType.company === true ? "flex" : "none" }}
                    >
                        <input
                            type="text"
                            name="company_name"
                            placeholder="Nazwa firmy"
                            value={sanitationOrder.companyName}
                            onChange={(e) => {
                                setSanitationOrder({ ...sanitationOrder, companyName: e.target.value })
                            }}
                        // required={clientType.company}
                        />
                        <input
                            type="text"
                            name="nip"
                            placeholder="NIP"
                            minLength='10'
                            maxLength='10'
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            value={sanitationOrder.nip}
                            onChange={(e) => {
                                setSanitationOrder({ ...sanitationOrder, nip: e.target.value })
                            }}
                        // required={clientType.company}
                        />
                        <input
                            type="text"
                            name="city"
                            placeholder="Miasto"
                            value={sanitationOrder.city}
                            onChange={(e) => {
                                setSanitationOrder({ ...sanitationOrder, city: e.target.value })
                            }}
                        // required
                        />
                        <InputMask
                            type="text"
                            placeholder="Kod pocztowy"
                            max={6}
                            mask="99-999"
                            value={sanitationOrder.postCode}
                            onChange={(e) => {
                                setSanitationOrder({ ...sanitationOrder, postCode: e.target.value })
                            }}
                        // required
                        />
                        <input
                            type="text"
                            name="street"
                            placeholder="Ulica"
                            value={sanitationOrder.street}
                            onChange={(e) => {
                                setSanitationOrder({ ...sanitationOrder, street: e.target.value })
                            }}
                        // required
                        />
                        <input
                            type="text"
                            name="placeNr"
                            placeholder="Nr budynku"
                            value={sanitationOrder.placeNr}
                            onChange={(e) => {
                                setSanitationOrder({ ...sanitationOrder, placeNr: e.target.value })
                            }}
                        // required
                        />
                        <input
                            type="text"
                            name="localNr"
                            placeholder="Nr lokalu"
                            value={sanitationOrder.localNr}
                            onChange={(e) => {
                                setSanitationOrder({ ...sanitationOrder, localNr: e.target.value })
                            }}
                        />
                        <input
                            type="email"
                            name="email"
                            placeholder="Adres e-mail"
                            value={sanitationOrder.email}
                            onChange={(e) => {
                                setSanitationOrder({ ...sanitationOrder, email: e.target.value })
                            }}
                        // required
                        />
                    </div>
                    <div className="formPersonalInfo__form--companyForm"
                        style={{ display: clientType.private === true ? "flex" : "none" }}
                    >
                        <input
                            type="text"
                            name="nameAndSurname"
                            placeholder="Imię i Nazwisko"
                            value={sanitationOrder.companyName}
                            onChange={(e) => {
                                setSanitationOrder({ ...sanitationOrder, companyName: e.target.value })
                            }}
                        // required={clientType.private}
                        />
                        <input
                            type="text"
                            placeholder="PESEL"
                            minLength='11'
                            maxLength='11'
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            value={sanitationOrder.pesel}
                            onChange={(e) => {
                                setSanitationOrder({ ...sanitationOrder, pesel: e.target.value })
                            }}
                        // required={clientType.private}
                        />
                        <input
                            type="text"
                            name="city"
                            placeholder="Miasto"
                            value={sanitationOrder.city}
                            onChange={(e) => {
                                setSanitationOrder({ ...sanitationOrder, city: e.target.value })
                            }}
                        // required
                        />
                        <InputMask
                            type="text"
                            placeholder="Kod pocztowy"
                            max={6}
                            mask="99-999"
                            value={sanitationOrder.postCode}
                            onChange={(e) => {
                                setSanitationOrder({ ...sanitationOrder, postCode: e.target.value })
                            }}
                        // required
                        />
                        <input
                            type="text"
                            name="street"
                            placeholder="Ulica"
                            value={sanitationOrder.street}
                            onChange={(e) => {
                                setSanitationOrder({ ...sanitationOrder, street: e.target.value })
                            }}
                        // required
                        />
                        <input
                            type="text"
                            name="placeNr"
                            placeholder="Nr budynku"
                            value={sanitationOrder.placeNr}
                            onChange={(e) => {
                                setSanitationOrder({ ...sanitationOrder, placeNr: e.target.value })
                            }}
                        // required
                        />
                        <input
                            type="text"
                            name="localNr"
                            placeholder="Nr lokalu"
                            value={sanitationOrder.localNr}
                            onChange={(e) => {
                                setSanitationOrder({ ...sanitationOrder, localNr: e.target.value })
                            }}
                        />
                        <input
                            type="email"
                            name="email"
                            placeholder="Adres e-mail"
                            value={sanitationOrder.email}
                            onChange={(e) => {
                                setSanitationOrder({ ...sanitationOrder, email: e.target.value })
                            }}
                        // required
                        />
                    </div>
                    <div className="formPersonalInfo__form--contactData">
                        <input
                            type="checkbox"
                            name="contactData"
                            checked={contactData}
                            onChange={e => setContactData(!contactData)}
                        />
                        {contactData ? inputChecked : inputNotChecked}
                        <label>Dodatkowy adres korespondencyjny</label>
                    </div>
                    <div className="formPersonalInfo__contactData"
                        style={{ display: contactData === true ? "flex" : "none" }}
                    >
                        <input
                            type="text"
                            name="correspName"
                            placeholder="Imię i Nazwisko"
                            value={sanitationOrder.correspName}
                            onChange={(e) => {
                                setSanitationOrder({ ...sanitationOrder, correspName: e.target.value })
                            }}
                        />
                        <input
                            type="text"
                            name="correspCity"
                            placeholder="Miasto"
                            value={sanitationOrder.correspCity}
                            onChange={(e) => {
                                setSanitationOrder({ ...sanitationOrder, correspCity: e.target.value })
                            }}
                        />
                        <InputMask
                            type="text"
                            placeholder="Kod pocztowy"
                            max={6}
                            mask="99-999"
                            value={sanitationOrder.correspPostCode}
                            onChange={(e) => {
                                setSanitationOrder({ ...sanitationOrder, correspPostCode: e.target.value })
                            }}
                        />
                        <input
                            type="text"
                            name="correspStreet"
                            placeholder="Ulica"
                            value={sanitationOrder.correspStreet}
                            onChange={(e) => {
                                setSanitationOrder({ ...sanitationOrder, correspStreet: e.target.value })
                            }}
                        />
                        <input
                            type="text"
                            name="correspPlaceNr"
                            placeholder="Nr budynku"
                            value={sanitationOrder.correspPlaceNr}
                            onChange={(e) => {
                                setSanitationOrder({ ...sanitationOrder, correspPlaceNr: e.target.value })
                            }}
                        />
                        <input
                            type="text"
                            name="correspLocalNr"
                            placeholder="Nr lokalu"
                            value={sanitationOrder.correspLocalNr}
                            onChange={(e) => {
                                setSanitationOrder({ ...sanitationOrder, correspLocalNr: e.target.value })
                            }}
                        />
                    </div>

                </div>
                <div className="formPersonalInfo--orderSummary">
                    <h3>SZCZEGÓŁY</h3>
                    <div className="formPersonalInfo--summary">
                        <div>
                            <label>RODZAJ NIECZYSTOŚCI:</label>
                            <p> {sanitationOrder.sanitationType} </p>
                        </div>
                        <div>
                            <label>ILOŚĆ m<sup>3</sup>:</label>
                            <p> {sanitationOrder.sanitationValue} m<sup>3</sup></p>
                        </div>
                        <div>
                            <label>ADRES ODBIORU NIECZYSTOŚCI:</label>
                            <p> ul. {sanitationOrder.orderStreet} {sanitationOrder.orderPlaceNr}, {sanitationOrder.orderPostcode} {sanitationOrder.orderCity}  </p>
                        </div>
                        <div>
                            <label>NUMER KONTAKTOWY:</label>
                            <p> {sanitationOrder.orderContactNumber} </p>
                        </div>
                        <div>
                            <label>PROPONOWANIA DATA WYWOZU:</label>
                            <p> {sanitationOrder.orderDate} </p>
                        </div>

                        <div className="formPersonalInfo__summary--price">
                            <h3>KOSZT</h3>
                            <div className="formPersonalInfo__price--price">
                                <div>{bascetcase}</div>
                                <div>
                                    <p><b>159</b> zł brutto</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='next__button--wrapper'>
                <p>krok: 2 z 4</p>
                <button type='submit' className="order--nextButton">DALEJ</button>
            </div>

        </form>
    )
}

export default SanitationPersonalInfo;