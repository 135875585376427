import React from 'react'
import '../styles/header.css'
import { TiSocialFacebook } from "react-icons/ti"
import { CgInstagram } from "react-icons/cg"
import { GrMail } from "react-icons/gr"
import { FaPhoneAlt } from "react-icons/fa"

const Header = () => (
    <div className="header--wrapper">
        <div className="header--mainContainer">
            <div className="header__mediaIcons--wrapper">
                <div className="header__mediaIcons--container">
                    <a href="https://facebook.com/serwistoalet/" target='_blank' rel="noopener noreferrer">
                        <TiSocialFacebook />
                    </a>
                </div>
                <div className="header__mediaIcons--container">
                    <a href="https://instagram.com/serwistoalet/" target='_blank' rel="noopener noreferrer">
                        <CgInstagram />
                    </a>
                </div>
            </div>
            <div className="header__contactIcons--wrapper">
                <div className="header__contactIcons--container">
                    <div className="header__contactIcons--iconContainer">
                        <GrMail className="header--contactIcon" />
                    </div>
                    <p>biuro@serwistoalet.pl</p>
                </div>
                <div className="header__contactIcons--container">
                    <div className="header__contactIcons--iconContainer">
                        <FaPhoneAlt className="header--contactIcon phone" />
                    </div>
                    <p>+48 733 435 435</p>
                </div>
            </div>
        </div>
    </div>
)

export default Header