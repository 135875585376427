import Logo from './logo.png'
import Truck from './truck.png'
import Toilets from './toilets.png'
import Slider from './slider.png'
import Top_baner from './top_baner.png'
import LiIcon from './li_icon.png'
import PartyImg from './baner_party.png'
import BuildPlaceImg from './baner_buildplace.png'
import EventsImg from './baner_events.png'
import SanitationImg from './baner_sanitation.png'
import SanitationImg2 from './baner_sanitation2.png'
import Clock from './ikons/12h.png'
import Boxes from './ikons/boxes.png'
import Call_center from './ikons/call_center.png'
import Clean from './ikons/clean.png'
import Location from './ikons/location.png'
import Smile from './ikons/smile.png'
import Tag from './ikons/tag.png'
import Toilet from './ikons/toilet.png'

import ServiceOneWeekBlue from './service_1_blue.png'
import ServiceTwoWeekBlue from './service_2_blue.png'
import ServiceFourWeekBlue from './service_4_blue.png'
import ServiceOneWeekWhite from './service_1_white.png'
import ServiceTwoWeekWhite from './service_2_white.png'
import ServiceFourWeekWhite from './service_4_white.png'
import ServiceOneWeekGrey from './service_1_grey.png'
import ServiceTwoWeekGrey from './service_2_grey.png'
import ServiceFourWeekGrey from './service_4_grey.png'
import Fusion1 from './cabins/fusion/fusion_1.png'
import Fusion2 from './cabins/fusion/fusion_2.png'
import Fusion3 from './cabins/fusion/fusion_3.png'
import Fusion4 from './cabins/fusion/fusion_4.png'
import Mtm1 from './cabins/mtm/mtm_1.png'
import Mtm2 from './cabins/mtm/mtm_2.png'
import Mtm3 from './cabins/mtm/mtm_3.png'
import Mtm4 from './cabins/mtm/mtm_4.png'

import ElblagCity from './cities/ElblagCity.png'
import MalborkCity from './cities/MalborkCity.png'
import MoragCity from './cities/MoragCity.png'

export const Images = {
    Logo,
    Truck,
    Toilets,
    Slider,    
    Top_baner,
    LiIcon,
    PartyImg,
    BuildPlaceImg,
    EventsImg,
    SanitationImg,
    SanitationImg2,
    ServiceOneWeekBlue,
    ServiceTwoWeekBlue ,
    ServiceFourWeekBlue,
    ServiceOneWeekWhite,
    ServiceTwoWeekWhite,
    ServiceFourWeekWhite,
    ServiceOneWeekGrey,
    ServiceTwoWeekGrey,
    ServiceFourWeekGrey
}

export const Icons = {
    Clock, 
    Boxes, 
    Call_center, 
    Clean,
    Location,
    Smile,
    Tag,
    Toilet 
}

export const FusionCabin = {
    Fusion1,
    Fusion2,
    Fusion3,
    Fusion4
}

export const MtmCabin = {
    Mtm1,
    Mtm2,
    Mtm3,
    Mtm4
}

export const Cities = {
    ElblagCity,
    MalborkCity,
    MoragCity
}