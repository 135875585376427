import { Icons } from "../assets/images/index.js";
import "../styles/benefits.css";

const Benefits = () => (
  <div className="benefits--wrapper">
    <h2>Dlaczego My?</h2>
    <div className="benefits__img--wrapper">
      <div className="benefits--box">
        <div className="benefits__box--icon">
            <img src={Icons.Clock} alt="Clock_icon" />
        </div>
        <div className="benefits__box--description">
            <span>Szybki czas realizacji <br/> zamówienia <br/>(nawet do 12h*)</span>
        </div>
      </div>
      <div className="benefits--box">
        <div className="benefits__box--icon">
            <img src={Icons.Location} alt="Location_icon" />
        </div>
        <div className="benefits__box--description">
            <span>Dostawa i instalacja <br/>kabiny WC we wskazanej <br/>lokalizacji</span>
        </div>
      </div>
      <div className="benefits--box">
        <div className="benefits__box--icon">
            <img src={Icons.Clean} alt="Clean_icon" />
        </div>
        <div className="benefits__box--description">
            <span>Kompleksowy serwis <br/>i utrzymanie toalet <br/> w nieskazitelnej czystości</span>
        </div>
      </div>
      <div className="benefits--box">
        <div className="benefits__box--icon">
            <img src={Icons.Boxes} alt="Boxes_icon" />
        </div>
        <div className="benefits__box--description">
            <span>Brak ograniczeń w zakresie <br/> ilości kabin WC oraz czasu <br/> wynajmu toalet</span>
        </div>
      </div>
    </div>
    <div className="benefits__img--wrapper">
    <div className="benefits--box">
        <div className="benefits__box--icon">
            <img src={Icons.Toilet} alt="Toilet_icon" />
        </div>
        <div className="benefits__box--description">
            <span>Różne modele <br/> przenośnych toalet <br/> (Fusion, MTM 2.0)</span>
        </div>
      </div>
      <div className="benefits--box">
        <div className="benefits__box--icon">
            <img src={Icons.Tag} alt="Tag_icon" />
        </div>
        <div className="benefits__box--description">
            <span>Atrakcyjne ceny <br/> i rabaty dla <br/>stałych klientów</span>
        </div>
      </div>
      <div className="benefits--box">
        <div className="benefits__box--icon">
            <img src={Icons.Call_center} alt="Call_center_icon" />
        </div>
        <div className="benefits__box--description">
            <span>Przyjmowanie <br/> zamówień w trybie <br/> 24/7</span>
        </div>
      </div>
      <div className="benefits--box">
        <div className="benefits__box--icon">
            <img src={Icons.Smile} alt="Smile_icon" />
        </div>
        <div className="benefits__box--description">
            <span>Tysiące <br/> zadowolonych <br/> klientów</span>
        </div>
      </div>
    </div>
  </div>
);

export default Benefits;
